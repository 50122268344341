@use "../../abstract" as *;
/*----------------------------
    blog Section Style 3
------------------------------*/ 

.blog-section-3{
    background-color: rgb(26, 36, 63);
    margin-top: -1px;
    padding-top: 50px;
    padding-bottom: 45px;
    @media (min-width:768px) {
        padding-top: 70px;
        padding-bottom: 62px;
    }
    @media (min-width:992px) {
        padding-top: 92px;
        padding-bottom: 82px;
    }
    @media (min-width:1200px) {
        padding-top: 108px;
        padding-bottom: 102px;
    }
    .section-title-block{
        display: flex;
        align-items: center;
        .title{
            text-align: center;
            color: #ffffff;
            @media (max-width:767px) {
                margin-bottom:30px;
               
            }
        }
        @media (max-width:767px) {
            flex-direction: column;
        }
        @media (min-width:768px) {
            justify-content: space-between;
        }
       
    }
    .blog-card-3{
        height: 100%;
    }
}