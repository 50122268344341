@use "../../abstract" as *; 

.about-section-4{
    padding-top: 60px;
    padding-bottom: 40px;
    @media (min-width:768px) {
        padding-top: 80px;
        padding-bottom: 0;
    }
    @media (min-width:992px) {
        padding-top: 100px;
    }
    @media (min-width:1200px) {
        padding-top: 120px;
    }
    .about-image{
        @media (min-width:992px) {
            margin-left: -14%;
        }
        img{
            width:100%;
        }
    }
    .about-right-block{
        @media (min-width:992px) {
            padding-left: 20px;
            
        }
        @media (min-width:1200px) {
            padding-left: 50px;
            
        }
    }
    .section-title-block{
        margin-bottom: 30px !important;
        @media (min-width:1400px) {
            margin-bottom: 50px !important;
            
        }
        .title{
            text-align: start;
            @media (min-width:1400px) {
                padding-right: 50px;
            }
            color: #000;
        }
        p{

        }
    }
    .about-widget{
        margin-bottom: 30px;
        @media (min-width:1400px) {
            margin-bottom: 50px;
            
        }
        .widget-single{
            display: flex;
            align-items: center;
            margin-bottom: 22px;
            &:last-child{
                margin-bottom: 0;
            }
            span{
                &:before{
                    color:$text;
                }
            }
            p{
                padding-left:17px;
            }
        }
    }
}
.widget-contact{
    display: flex;
    align-items: center;
    .contact-icon{
        @include square(60px,500px);
        @include flexAllCenter;
        background: $primary;
        margin-right: 20px;
        span{
            font-size: 20px;
            color:#fff;
        }
    }
    .contact-text{
        p{
            color: #171F36;
            font-weight: 700 !important;
            font-size: 18px;
            line-height: 30px;
            margin-bottom: 5px;
            &:last-child{
                margin-bottom: 0;
            }

        }
    }
}