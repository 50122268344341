@use "../abstract" as *;
.footer-section{
    background: #1A243F;
    position: relative;
    padding-top: 60px;
    @media (min-width:768px) {
        padding-top: 80px;
    }
    @media (min-width:992px) {
        padding-top: 100px;
    }
    @media (min-width:1200px) {
        padding-top: 120px;
    }
    p{
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: rgb(255, 255, 255, 0.7);
    }
    .social-icon{
        display: flex;
        margin-top: 25px;
        margin-bottom: 10px;
        margin-left: -7px;
        margin-right: -7px;
        .icon-link{
            min-width: 32px;
            max-width: 32px;
            min-height: 32px;
            max-height: 32px; 
            color: #FFFFFF;
            padding: 5px;
            margin-left: 7px;
            margin-right: 7px;
            @include flexAllCenter;
            transition: 0.3s;
            &:hover{
                background: $primary;
                border-color: $primary;
                color: $white;
                border-radius: 500px;
            }
        }

    }
    .liner{
        padding-top: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
        padding-right: 100px;
    }
    .liner-2{
        margin-top: 40px;
    }
    .footer-block-title{
        font-weight: 500;
        font-size: 24px;
        line-height: 34px;
        color: #FFFFFF;
        margin-bottom: 25px;
    }
    .pr-40{
        padding-right: 20px;
        @media (min-width:576px) {
            padding-right: 0;
        }
        @media (min-width: 992px) {
            padding-right: 20px;
        }
        @media (min-width: 1200px) {
            padding-right: 40px;
        }
    }
    .footer-contact-block{
        @media (min-width: 1200px) {
            padding-left: 20px;
        }
        @media (min-width: 1400px) {
            padding-left: 60px;
        }
    }
}
.footer-latest-news{
    .single-news{
        +.single-news{
            margin-top: 10px;
            padding-top: 20px;
            border-top: 1px solid rgba(#fff,.1);
        }
        .date{
            font-weight: 400;
            font-size: 15px;
            line-height: 27px;
            color: #FFFFFF;
        }
        
        *{
            color: #FFFFFF;
        }
        .title{
            transition: .4s;
        }
        &:hover{
            .title{
                color: $primary;
            }
        }
    }
}
.footer-contact-list{
    span{
        font-weight: 400;
        font-size: 18px;
        line-height: line-height(30,18);
        color: rgb(255, 255, 255, 0.7);
        display: block;
    }
    li{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        color:  #FFFFFF;
        >i,.item-icon{
            max-width: 18px;
            position: absolute;
            left: 0;
            top:7px;
            +span,+a,+p{
                padding-left: 28px;
                color: inherit;
            }
        }
        a{
            transition: .4s;
            &:hover{
                color: $primary;
            }
        }
    }
}
.footer-brand{
    padding-right: 15px;
    padding-bottom: 23px;
    img{
        width: 100%;
    }
}

.footer-inner{
    padding-bottom: 60px;
}
.copyright-block{
    padding-top: 25px;
    padding-bottom: 30px;
    border-top: 1px solid rgba(#fff,.1);
    p{
        font-weight: 400;
        font-size: 15px;
        line-height: 27px;
        color: rgba(#fff,.7);
    }
    a,span{
        font-weight: 400;
        font-size: 15px;
        line-height: line-height(27,15);
        color: rgb(255, 255, 255, 0.7);
    }
    a:hover{
        color: #FFFFFF;
        transition: .4s;
    }
}

.footer-section--light{
    background-color: rgba(85, 92, 113, 0.05);
    p{
        color: rgb(0, 0, 0, 0.6);
    }
    .social-icon{
        .icon-link{
            color: $secondary;
        }
    }
    .footer-block-title{
        color: $secondary;
    }
    .footer-latest-news{
        .single-news{
            +.single-news{
                border-top: 1px solid rgba(#000,.1);
            }
            .date{
                color: rgb(0, 0, 0, 0.6);
            }
            
            *{
                color: rgb(0, 0, 0, 0.6);
            }
        }
    }
    .footer-contact-list{
        span{
            color: rgb(0, 0, 0, 0.6);
        }
        li{
            color: rgb(0, 0, 0, 0.6);
        }
    }
    .copyright-block{
        border-top: 1px solid rgba(#000,.1);
        p{
            color: rgb(0, 0, 0, 0.6);
        }
        a,span{
            color: rgb(0, 0, 0, 0.6);
        }
        a:hover{
            color: rgb(0, 0, 0);
        }
    }
}