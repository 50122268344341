/*----------------------------
    case Section Style 1 
------------------------------*/ 
@use "../../abstract" as *;

.case-study{
    background-color: rgb(85, 92, 113, 0.05);
    padding-top: 50px;
    padding-bottom: 50px;
    @media (min-width:768px) {
        padding-top: 66px;
        padding-bottom: 70px;
    }
    @media (min-width:992px) {
        padding-top: 86px;
        padding-bottom: 90px;
    }
    @media (min-width:1200px) {
        padding-top: 106px;
        padding-bottom: 110px;
    }
    .row{
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 1.5rem;
    }
    .section-title-block{
        text-align: center;
        margin-bottom:40px;
        color: $big-stone;
        @media (min-width:768px) {
            margin-bottom:50px;
        }
        @media (min-width:992px) {
            margin-bottom:60px;
        }
        @media (min-width:1200px) {
            margin-bottom:80px;
        }
        .title{
            text-align: center;
            color: $big-stone;
        }
    }
    .case-study-card{
        position: relative;
        overflow: hidden;
        // .card-image{
        //     img{
        //         width: 100%;
        //         border-radius: 15px;
        //     }
        // }
    }
    &__button{
        margin: 0 auto;
        .btn{
            margin-top: 40px;
            margin-left: auto;
            margin-right: auto;
            @media (min-width:992px) {
                margin-top: 80px;
               
            }
        }
    }
}
.case-hover-visible{
    transform: translateY(0%);
}