@use "../../abstract/" as *;

.comming-soon-2{
    background: rgb(100, 96, 239, 0.05);
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
   
    .error-image{
        margin-bottom: 30px;
    }
    .comming-logo{
        display: flex;
        justify-content: center;
       margin-bottom: 30px;
    }
    .section-title-block{
        color: $big-stone !important;
        .comming-title{
            color: $big-stone !important;
        }
    }
    .countdown {

        ul {
            li {
                color: $comet;
                
                span {
                    color: #6460EF;
                }
            }
        }
    }
    .form-single{
        max-width: 640px;
        margin: 0 auto;
        .form-control{
            box-shadow: 0px 15px 30px rgb(23 31 54 / 5%);
            border: 0;
            @media (min-width:1200px) {
                max-width: 100%;
                
            }
        }
        .btn{
            color:#FFFFFF;
            width: 100%;
            min-height: 63px;
            max-height: 63px;
            background-color: $big-stone;
            border-radius:5px;
            @media (min-width:480px) {
                border-radius: 0px 5px 5px 0px;
                min-width: 141px;
                max-width: 141px;
                position: absolute;
                top: 0;
                right: 0;  
            }
            &:hover{
                color: #FFFFFF;
                background-color: #4742ca;
                border-color: #4742ca;
            }
        }
        .cheackbox{
            span{
                color:$big-stone;
            }
            .checkmark {
                background: rgb(26, 36, 63, 0.3);
            }
        }
    }
}