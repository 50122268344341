.bg-image{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.object-image{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bg-parallax{
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}