@use "../../abstract" as *;

.contact-section{
    padding-top:60px;
    padding-bottom: 30px;
    @media (min-width:768px) {
        padding-top:80px
    }
    @media (min-width:992px) {
        padding-top:100px
    }
    @media (min-width:1200px) {
        padding-top:120px
    }
    .left-side{
        @media (max-width:991px) {
            margin-bottom:40px;
        }
        .contact-image{
            margin-bottom: 50px;
            img{
                width:100%;
            }
        }
        p{
            margin-bottom: 50px;
        }
        .user{
            .user-signature{
                margin-bottom: 8px;
            }
            p{
                margin-bottom: 0;
            }
        }
    }
    .form-box{
        @media (min-width:1200px) {
            padding-left:100px;
        }
    }
}