@use "../abstract/" as *;

.error-section{
    min-height: 100vh;
    display: flex;
    align-items: center;
    .error-image{
        margin-bottom: 30px;
    }
    .section-title-block{
        text-align: center;
        
        .btn{
            margin: 30px auto 0 auto;
        }
    }
}