@use "../abstract/" as *;

/*---- home-1 brand section style-------- */
.brand-section-1{
    background: #fff;
    padding-top: 60px;
    padding-bottom: 60px;
    @media (min-width:768px) {
        padding-top: 90px;
        padding-bottom: 65px;
    }
    @media (min-width:992px) {
        padding-top: 75px;
        padding-bottom: 80px;
    }
    @media (min-width:1200px) {
        padding-top: 95px;
        padding-bottom: 100px;
    }
    
    .section-title-block{
        text-align: center;
        margin-bottom: 30px;
        @media (min-width:1200px) {
            text-align: left;
            margin-bottom: 0;
        }
        .title{
            color: $secondary;
            margin-bottom:15px;
            
        }
    }
    .brand-group{
        flex-wrap: wrap;
        justify-content: center;
        .brand-single{
            width: 33.333%;
            @media (min-width:768px) {
                width: 21%;
            }
            @media (min-width:1200px) {
                width: 33.333%;
            }
            img{
                width: 100%;
            }
        }
    }
}


/*---- home-2 brand section style-------- */
.brand-section-2{
    padding-top: 60px;
    padding-bottom: 60px;
    .brand-single{
        display: flex;
        justify-content: center;
    }
}

/*---- home-3 brand section style-------- */
.brand-section-3{
    background-color: #1A243F;
    padding-top: 49px;
    padding-bottom: 37px;
    @media (min-width:768px) {
        padding-top: 90px;
        padding-bottom: 55px;
    }
    @media (min-width:992px) {
        padding-top: 95px;
        padding-bottom: 75px;
    }
    @media (min-width:1200px) {
        padding-top: 95px;
        padding-bottom: 88px;
    }
    .brand-single{
        display: flex;
        justify-content: center;
        img{
            width: 100%;
        }
    }
}

/*---- brand global style-------- */
// .brand-section-padding{
//     background: #fff;
//     padding-bottom: 37px;
//     padding-top: 430px ;
//     @media (min-width:480px) {
//         padding-top: 330px;
//     }
//     @media (min-width:768px) {
//         padding-top: 90px;
//         padding-bottom: 55px;
//     }
//     @media (min-width:992px) {
//         padding-top: 95px;
//         padding-bottom: 75px;
//     }
//     @media (min-width:1200px) {
//         padding-top: 95px;
//         padding-bottom: 88px;
//     }
    
//     .section-title-block{
//         text-align: center;
//         margin-bottom: 30px;
//         @media (min-width:1200px) {
//             text-align: left;
//             margin-bottom: 0;
//         }
//         .title{
//             color: $secondary;
//             margin-bottom:15px;
//             @media (min-width:768px) {
//                 padding: 0 12px;
//             }
//         }
//     }
//     .brand-group{
//         flex-wrap: wrap;
//         justify-content: center;
//         .brand-single{
//             width: 33.333%;
//             @media (min-width:768px) {
//                 width: 21%;
//             }
//             @media (min-width:1200px) {
//                 width: 33.333%;
//             }
//             img{
//                 width: 100%;
//             }
//         }
//     }
// }


