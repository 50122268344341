@use "../../abstract" as *;
/*----------------------------
    service Section Style 2 
------------------------------*/ 

.service-section-2 {
    padding-top: 40px;
    background: #ffffff;
    position: relative;
    z-index: 1;
    padding-bottom: 50px;
    @media (min-width:576px) {
        padding-top: 50px;
    }
    @media (min-width:768px) {
        padding-top: 70px;
    }
    @media (min-width:992px) {
        padding-top: 90px;
    }
    @media (min-width:1200px) {
        padding-top: 110px;
    }
    &::before{
        content:"";
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgb(26, 36, 63);
        width: 100%;
        height: 81%;
        z-index: -1;
        @media (max-width:768px) {
            height: 100%;
        }
    }
    .row{
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 1.5rem;
    }
    .section-title-block {
        text-align: center;
        color: #ffffff;
        @media (min-width:1400px) {
            padding:0 20px;
        }
        .title{
            text-align: center;
            color: #ffffff;
            
        }
        p{
            color: rgb(255, 255, 255, 0.7);
        }
    }

}
