@use "../../abstract" as *;
/*----------------------------
    service Section Style 1 
------------------------------*/ 

.services-section{
    padding-top: 60px;
    padding-bottom: 60px;
    @media (min-width:768px) {
        padding-top: 80px;
        padding-bottom: 60px;
    }
    @media (min-width:992px) {
        padding-top: 100px;
        padding-bottom: 90px;
    }
    @media (min-width:1200px) {
        padding-top: 120px;
        padding-bottom: 90px;
    }
    .service-card-row{
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 1.5rem;
    }

    .section-title-block{
        margin-bottom: 40px;
        @media (min-width:768px) {
            margin-bottom: 60px;
        }
        @media (min-width:1200px) {
            margin-bottom: 80px;
        }
        .title{
            text-align: center !important;
            color: $big-stone;
        }
    }
}
