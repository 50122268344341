@use "../../abstract" as *;

.accordion-section{
    padding-top: 50px;
    padding-bottom: 60px;
    @media (min-width:768px) {
        padding-top: 70px;
        padding-bottom: 80px;
    }
    @media (min-width:992px) {
        padding-top: 92px;
        padding-bottom: 100px;
    }
    @media (min-width:1200px) {
        padding-top: 108px;
        padding-bottom: 120px;
    }
}
.accordion-style-1{
    .accordion-item{
        background: rgba(100, 96, 239, 0.05);
        box-shadow: -4px 8px 12px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        border: none;
        
        .accordion-button{
            border: 0;
            border-color: transparent;
            background: transparent;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            line-height: line-height(30,20);
            margin-bottom: 0;
            border-radius: 15px;
            &:after{
                flex-shrink: 0;
                width: 1.25rem;
                height: 1.25rem;
                margin-left: auto;
                margin-top: -30px;
                padding-left: 20px;
                padding-right: 20px;
                content: "+";
                background-image: none;
                background-repeat: no-repeat;
                background-size: 1.25rem;
                transition: transform 0.2s ease-in-out;
                font-size: 31px;
               
            }
        }
        .accordion-button:not(.collapsed)::after {
            content: "-";
            transform:rotate(0);
        }
        .accordion-body{
            padding:20px;
            z-index: -1;
            p{
                font-weight: 400;
                font-size: 18px;
                line-height: 30px;
                color: #555C71;
            }
        }
        .accordion-button:not(.collapsed) {
            color: rgb(255, 255, 255, 0.9);
            box-shadow: none;
            background-color: $primary;
            transition: .4s;
        }
    }
}