@use "../../abstract" as *;

.terms-section{
    padding-top:60px;
    padding-bottom: 60px;
    @media (min-width:768px) {
        padding-top:80px;;
        padding-bottom:80px;
    }
    @media (min-width:992px) {
        padding-top:100px;
        padding-bottom:100px;
    }
    @media (min-width:1200px) {
        padding-top:120px;
        padding-bottom:120px;
    }
    .terms-content-wrapper{
        p{
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            line-height: line-height(30,20);
            margin-bottom: 30px;
            color: $text-color;
        }
        span{
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
            line-height: line-height(30,20);
            color: #1A243F;
        }
        .text-heading{
            font-weight: 700;
            font-size: 30px;
            line-height: 40px;
            color: #1A243F;
            margin-bottom: 15px;
        }
    }
}