@use "../../abstract" as *;
/*----------------------------
    faq Section Style 3
------------------------------*/ 

.faq-section-3{
    padding-top: 50px;
    padding-bottom: 60px;
    @media (min-width:768px) {
        padding-top: 70px;
        padding-bottom: 80px;
    }
    @media (min-width:992px) {
        padding-top: 92px;
        padding-bottom: 100px;
    }
    @media (min-width:1200px) {
        padding-top: 108px;
        padding-bottom: 120px;
    }
    .row{
        --bs-gutter-x: 30px;
        --bs-gutter-y: 30px;
    }
    .section-title-block{
        text-align: center;
        .title{
            text-align: center;
            color: $secondary;
        }
        p{
            @media (min-width:992px) {
                padding-left: 60px;
                padding-right: 60px;
            }
            @media (min-width:1200px) {
                padding-left: 20px;
                padding-right: 20px;
            }
            
        }
    }
}
.faq-image{
    margin-bottom: 20px;
    @media (min-width:992px) {
        margin-bottom: 0;
    }
    @media (min-width:1400px) {
        padding-left: 58px;
    }
    img{
        width:100%;
        border-radius: 15px;
    }
}