@use "../../abstract" as *;

/*----------------------------
    blog Section Style 2 
------------------------------*/ 

.blog-section-2{
    padding-top: 60px;
    @media (min-width:768px) {
        padding-top: 80px;
    }
    @media (min-width:992px) {
        padding-top: 100px;
    }
    @media (min-width:1200px) {
        padding-top: 120px;
    }
    .section-title-block{
        margin-bottom:40px;
        text-align:center;
        .title{
            margin-bottom:30px;
            @media (min-width:768px) {
                margin-bottom:0;
            }
        }
        @media (min-width:768px) {
            display: flex;
            text-align: start;
            justify-content: space-between;
            margin-bottom:50px;
        }
        @media (min-width:992px) {
            margin-bottom:60px;
        }
        @media (min-width:1200px) {
            margin-bottom:80px;
        }
    }
    &_btn{
        .btn{
            @media (max-width:767px) {
                margin:0 auto;
            }
        }
    }
    .row{
        --bs-gutter-x: 24px;
        --bs-gutter-y: 24px;
    }
    // .blog-card{
    //     .card-image{
    //         position: relative;
    //         overflow: hidden;
    //         border-radius: 15px;
    //         img{
    //             width: 100%;
    //             border-radius: 15px;
    //         }
    //     }
    //     .card-image:hover .blog-image-hover{
    //         transform: translateY(0%);
           
    //     }
    //     .blog-image-hover{
    //         display: flex;
    //         transition: 0.7s;
    //         align-items: flex-end;
    //         justify-content: center;
    //         position: absolute;
    //         bottom: 0;
    //         left: 0;
    //         right: 0;
    //         width: 100%;
    //         height: 100%;
    //         padding: 20px 20px;
    //         background: $secondary;
    //         @media (min-width:992px) {
    //             background: linear-gradient(184.1deg, rgba(26, 36, 63, 0) -0.16%, rgba(26, 36, 63, 0.83) 66.64%, #1A243F 93.16%);
    //             padding: 30px 30px;
    //             transform: translateY(100%);
    //             margin: 0;
    //         }

    //         .hover-text{
    //             margin-right: 15px;
    //             .hover-title{
    //                 font-weight: 700;
    //                 font-size: 22px;
    //                 line-height: 32px;
    //                 color: #FFFFFF;
    //             }
    //             .subtitle{
    //                 font-weight: 400;
    //                 font-size: 16px;
    //                 line-height: 27px;
    //                 color: #FFFFFF;
    //                 opacity: 0.7;
    //                 margin-bottom: 5px;
    //             }
    //         }
           
    //         .hover-btn{
    //             .btn{
    //                 font-weight: 700;
    //                 font-size: 18px;
    //                 line-height: 27px;
    //                 color: #FFFFFF;
    //                 padding: 0;
    //                 min-width: 50px;
    //                 max-width: 50px;
    //                 min-height:50px;
    //                 max-height:50px;
    //                 background: #6460EF;
    //                 border-radius: 500px;
    //                 display: flex;
    //                 justify-content: center;
    //                 align-items: center;
    //                 &:hover{
    //                     background: transparent;
    //                     border: 1px solid #ffffff;
    //                     border-radius: 500px;
    //                 }
    //             }
    //         }
    //     }
    // }
}



