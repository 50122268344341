@use "../abstract/" as *;

.title-block-padding {
    margin-bottom: 40px;

    @media (min-width:576px) {
        margin-bottom: 50px;
    }

    @media (min-width:768px) {
        margin-bottom: 60px;
    }

    @media (min-width:992px) {
        margin-bottom: 80px;
    }
}

/* typo global Styles */

.heading {
    &:not(.heading--xl):not(.heading--lg):not(.heading--md):not(.heading--sm):not(.heading--xs) {
        font-weight: 700;
        font-size: 32px;
        line-height: line-height(65, 54);

        @media (min-width:480px) {
            font-size: 36px;
        }

        @media (min-width:768px) {
            font-size: 40px;
        }

        @media (min-width:1200px) {
            font-size: 54px;
        }

    }

    &--xl {
        font-weight: 700;
        line-height: line-height(90, 80);
        font-size: 42px;
        color: #FFFFFF;


        @media (min-width:576px) {
            font-size: 48px;
        }

        @media (min-width:768px) {
            font-size: 58px;
        }

        @media (min-width:1200px) {
            font-size: 80px;
        }
    }

    &--lg {
        font-weight: 700;
        font-size: 28px;
        line-height: line-height(50,40);
        @media (min-width:576px) {
            font-size: 35px;
        }
        @media (min-width:576px) {
            font-size: 40px;
        }
    }

    &--md {
        font-weight: 700;
        font-size: 18px;
        line-height: line-height(32, 22);

        @media (min-width:576px) {
            font-size: 20px;
        }

        @media (min-width:768px) {
            font-size: 22px;
        }
    }

    &--sm {
        font-weight: 700;
        font-size: 18px;
        line-height: line-height(27, 18);
    }

    &--xs {
        font-weight: 700;
        font-size: 12px;
        line-height: line-height(20, 12);
    }

}

.text {
    &:not(.text--lg):not(.text--md):not(.text--sm):not(.text--xs) {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        line-height: line-height(30, 20);

        @media (min-width:768px) {
            font-size: 20px;
        }
    }

    &--lg {
        font-weight: 400;
        font-size: 22px;
        line-height: line-height(40, 30);

        @media (min-width:576px) {
            font-size: 25px;
        }

        @media (min-width:768px) {
            font-size: 30px;
        }
    }

    &--md {
        font-weight: 700;
        font-size: 26px;
        line-height: line-height(40, 26);
    }

    &--sm {
        font-weight: 400;
        font-size: 16px;
        line-height: line-height(30, 18);
        @media (min-width:576px) {
            font-size: 18px;
        }
    }

    &--xs {
        font-weight: 400;
        font-size: 16px;
        line-height: line-height(27, 16);
    }

}