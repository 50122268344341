@use "../../abstract" as *;
/*----------------------------
    case Section Style 2 
------------------------------*/ 

.case-study-section-2 {
    background-color: rgb(85, 92, 113, 0.05);
    font-family: $font-mulish;
    padding-bottom: 50px;
    padding-top: 50px;

    @media (min-width:768px) {
        padding-bottom: 70px;
        padding-top: 70px;
    }

    @media (min-width:992px) {
        padding-bottom: 90px;
    }

    @media (min-width:1200px) {
        padding-bottom: 110px;
    }

    .row {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 1.5rem;
    }

    .section-title-block {
        text-align: center;
        margin-bottom: 30px;

        @media (min-width:768px) {
            margin-bottom: 50px;
        }

        @media (min-width:992px) {
            margin-bottom: 60px;
        }

        .title {
            text-align: center;
        }
    }

    .px-0 {
        --bs-gutter-x: 0;
    }

    .slider-area {
        position: relative;
        z-index: 1;
    }

    .case-study-slider {
        padding-right:- 12px;
        padding-left: -12px;
        max-width: 100%;
        margin: 0 auto;
        @media (min-width:480px) {
            max-width: 80%;
        }
        @media (min-width:576px) {
            max-width: 100%;
        }
        .slick-track{
            display: flex;
            .slick-slide{
                height: auto;
            }
        }
        .single-slider {
            padding-right: 12px;
            padding-left: 12px;
        }
    }
    .slider-pagination {
        display: flex;
        margin: 0 auto;
        justify-content: center;
        margin-top: 50px;

        .slider-btn {
            margin-right: 10px;
            box-shadow: none;
            background: rgb(26, 36, 63, 0.1);
            span{
                color: $primary;
            }
            &:hover{
                background: $primary;
                span{
                    color: #fff;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}