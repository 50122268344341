@use "../../abstract" as *;
/*----------------------------
    hero Section Style 1 
------------------------------*/ 

.hero-area {
    position: relative;
    &-inner {
        background-image: url(../image/png/breadcrumb-bg-image.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #1a243f;
        padding-top: 134px;
        margin-bottom: 335px;
        @media (min-width:768px) {
            padding-top: 150px;
            margin-bottom: 0;
        }

        @media (min-width:992px) {
            padding-top: 190px;
        }
    }

    &__content {
        margin-bottom: 20px;

        @media (min-width:576px) {
            margin-bottom: 40px;
        }

        @media (min-width:992px) {
            margin-bottom: 58px;
        }

        .title {
            font-weight: 700;
            font-size: 35px;
            line-height: 48px;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 15px;

            @media (min-width:576px) {
                font-size: 40px;
                line-height: 56px;
            }

            @media (min-width:768px) {
                font-size: 54px;
                line-height: 66px;
            }

            @media (min-width:1200px) {
                font-size: 80px;
                line-height: 90px;
            }
        }

        p {
            text-align: center;
            color: #FFFFFF;

        }
    }

    .hero-area__image {
        img {
            width: 100%;
            border-radius: 10px 10px 0px 0px;
        }
    }
    .form-section {
        position: absolute;
        bottom: -62%;
        z-index: 2;
        left: 0;
        width: 100%;
        padding-top: 0px;
        padding-bottom: 0px;
        background: transparent;
        @media (min-width:480px) {
            bottom: -56%;
        }
        @media (min-width:768px) {
            bottom: -6%;
        }
    }

    @media (min-width:1200px) {
        .form-section {
            bottom: -3%;

        }
    }
}

.form-section {
    padding-bottom: 55px;
    font-size: 16px;
    background: rgba(#555C71, 0.05);
    @media (min-width:1400px) {
        --font-size: 18px;
    }

    .form-box {
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        background: #FFFFFF;
        --bs-gutter-x: 32px;
        --bs-gutter-y: 20px;
        padding: 30px 20px;
        margin-left: 0px;
        margin-right: 0px;

        @media (min-width:400px) {
            padding: 30px;
        }
        @media (min-width:575px) {
            margin-left: 55px;
            margin-right: 55px;
        }

        @media (min-width:992px) {
            padding: 36px 46px 54px;
        }
        @media (min-width:1400px) {
            padding: 56px 48px;
        }

        .form-group {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            height: 100%;

            .label {
                font-weight: 400;
                font-size: 10px;
                line-height: 20px;
                color: #1A243F;
                margin-bottom: 10px;
            }
        }

        .btn {
            @media (min-width:1200px) {
                padding: 16px 7px;
                margin-right: 0;
                margin-left: auto;

            }

            @media (min-width:1400px) {
                padding: 16px 23px;
            }
        }

    }

    .form-select {
        font-weight: 400;
        line-height: 1;
        color: rgba(#1A243F, 0.5);
        height: 35px;
        border: 0;
        border-radius: 0;
        padding-top: 5px;
        padding-bottom: 10px;
        padding-left: 0;
        padding-right: 0;
        float: none;
        border-bottom: 1px solid rgb(26, 36, 63, 0.2);
        font-size: var(--font-size);
        background-image: none;

        .current {
            color: rgba(#1A243F, 0.5);
        }

        .list {
            width: 100%;
            margin-bottom: 0;
            padding-top: 20px;
            padding-bottom: 20px;

            li {
                font-size: 16px;
                color: rgba(#1A243F, 0.7);
                padding-left: 30px;
            }
        }

        &:after {
            background-image: url("../image/svg/select-arrow.svg");
            content: "";
            border: 0;
            background-repeat: no-repeat;
            width: 10px;
            height: 10px;
            transform: rotate(0deg);
            right: 0;
            transform-origin: center;
            background-position: center;
            transform-box: fill-box;
            margin-top: -8px;
        }

        &:focus,
        &.nice-select:focus {
            box-shadow: none;
            border-bottom: 1px solid $primary;
        }

        &.open {
            &:after {
                transform: rotate(180deg);
            }
        }
    }

    .home-form-control {
        font-weight: 400;
        color: rgba(#1A243F, 0.5);
        background: transparent;
        height: 35px;
        padding-top: 5px;
        padding-bottom: 10px;
        padding-left: 0;
        padding-right: 0;
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid rgb(26, 36, 63, 0.2);
        font-size: var(--font-size);
        min-height: auto;

        &::placeholder {
            color: rgba(#1A243F, 0.5);
        }

        &:focus {
            background: transparent;
            border-bottom: 1px solid $primary;
        }

        &:focus,
        &:active {
            background: transparent;
        }
    }
}