@use "../../abstract/" as *;

%blogcommon{
  
}

// use in homepage-1
.blog-card {
  --card-radius: 15px;
  position: relative;
  overflow: hidden;
  border-radius: var(--card-radius);

  .card-image {
    border-top-left-radius: var(--card-radius);
    border-top-right-radius: var(--card-radius);
    img {
      width: 100%;
    }

    @media (max-width:991px) {
      max-height: 245px;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .card-body {
    padding: 30px;
    color: #fff;
    background: $primary;
    &:hover .card-link{
      color: #fff;
    }
  }

  .card-title {
    color: inherit;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: '-webkit-box';
    /* line-height: 16px; */
    max-height: 90px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .card-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: rgb(255, 255, 255, 0.7);
    margin-bottom: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: '-webkit-box';
    /* line-height: 16px; */
    max-height: 90px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .card-link {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: rgb(255, 255, 255, 0.7);
    text-decoration: underline;
    padding: 0;
  }

  &.hover-visible-2 {
    @media (min-width:992px) {
      .card-body{
        padding: 48px;
        transition: 0.4s;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        position: absolute;
        background: linear-gradient(184.1deg,
            rgba(26, 36, 63, 0) -0.16%,
            rgba(26, 36, 63, 0.83) 66.64%,
            #1a243f 93.16%);
        transform: translateY(0%);
      }
    }
  }
  &:not(.hover-visible):not(.hover-visible-2) {
    @media (min-width: 992px) {
      .card-image {
        border-bottom-left-radius: var(--card-radius);
        border-bottom-right-radius: var(--card-radius);
      }
  
      .card-body {
        padding: 35px 30px;
        transition: 0.4s;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        position: absolute;
        background: linear-gradient(184.1deg,
            rgba(26, 36, 63, 0) -0.16%,
            rgba(26, 36, 63, 0.83) 66.64%,
            #1a243f 93.16%);
        transform: translateY(150%);
      }
  
      &:hover {
        .card-body {
          transform: translateY(0%);
        }
      }
    }
  }
}

// use in homepage-2




.blog-card-2 {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover {
    .card-body {
      @media (min-width: 992px) {
        transform: translateY(0%);
      }
    }
  }
  .card-image {
    overflow: hidden;
    border-radius: 15px;

    img {
      width: 100%;
      @media (max-width:991px) {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        
      }
      @media (min-width:992px) {
        border-radius: 15px;
      }
    }
  }

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background:$secondary;
      @media (min-width: 992px) {
      background: linear-gradient(184.1deg,
      rgba(26, 36, 63, 0) -0.16%,
        rgba(26, 36, 63, 0.83) 66.64%,
        #1a243f 93.16%);
      border-radius: 15px;
      padding: 48px;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      overflow: hidden;
      transition: 0.4s;
      transform: translateY(100%);
    }
    .hover-title {
      color: #ffffff;
    }
    .hover-text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .subtitle {
        color: #ffffff;
        opacity: 0.7;
        margin-bottom: 5px;
      }
    }

    .hover-btn {
      .btn {
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        color: #ffffff;
        padding: 0;
        @include square(40px,500px);
        background: #6460ef;
        border-radius: 500px;
        display: flex;
        justify-content: center;
        align-items: center;

      
      }
    }
    
  }
  &.hover-visible-2 {
    @media (max-width:991px) {
      .card-body{
        padding: 30px;
        transition: 0.4s;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        transform: translateY(0%);
      }
    }
  }
}

// use in homepage-3

.blog-card-3 {
  overflow: hidden;
  background: #ffffff;
  border-radius: 15px;
  .card-image {
    img {
      width: 100%;
      border-radius: 15px 15px 0 0;
    }
  }

  .card-content {
    padding: 20px;
    @media (min-width: 350px) {
      padding: 40px;
    }
    @media (min-width: 992px) {
      padding: 20px;
    }
    @media (min-width: 1400px) {
      padding: 40px;
    }
    .subtitle {
      color: #555c71;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 15px;
    }

    .title {
      color: $heading-color;
      margin-bottom: 0;
    }
  }
}