@use "../../../abstract" as *;


/*fact-block-1 Styles */

.fact-block-1 {
    width: 100%;
    max-width: 88%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .fact-item {
        text-align: center;

        @media (max-width:427px) {
            margin-bottom: 30px
        }

        .fact-title--lg {
            font-weight: 700;
            font-size: 35px;
            line-height: line-height(90, 80);
            color: $primary;

            @media (min-width:576px) {}

            @media (min-width:768px) {
                font-size: 52px;
            }

            @media (min-width:992px) {
                font-size: 64px;
            }

            @media (min-width:1200px) {
                font-size: 80px;
            }
        }

    }
}

.fact-block-2{
    display: flex;
    justify-content: center;
}