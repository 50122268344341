@use "../../abstract" as *;

/*----------------------------
    About Section Style 3 
------------------------------*/
.about-section-3 {
    position: relative;

    &__image {
        filter: drop-shadow(0px 15px 30px rgba(0, 0, 0, 0.25));
        padding-right: 24%;
        position: relative;
        padding-bottom: 7%;

        img {
            border-radius: 15px;
            width: 100%;
        }

        .about-image-2 {
            border-radius: 10px;
            position: absolute;
            bottom: 0%;
            right: 0%;
            max-width: 52%;

            @media (min-width:1200px) {
                right: -3%;
            }

            img {
               
           
                position: relative;
                border-radius: 10px;
            }
        }
    }

    &__content {
        
        @media(min-width:1200px) {
            margin-left: -50px;
        }

        @media(min-width:1400px) {
            padding-left: 40px;
            margin-left: 0;
        }

        .section-title-block {
            margin-bottom: 30px !important;
            .title{
                text-align: start;
            }

        }

    }
    &__list {
        @media(max-width:991px) {
        }
        ul {
            margin-bottom: 30px;
            padding-left: 0;
            @media(min-width:480px) {
                display: flex;
                flex-wrap: wrap;
            }
        }
    
        li {
            display: flex;
            width: 100%;
            margin-bottom: 20px;
            padding-right: 10px;
            @media(min-width:480px) {
                max-width: 50%;
            }
    
            p {
                font-weight: 400;
                font-size: 18px;
                line-height: line-height(30, 20);
                color: #555C71;
                @media(min-width:992px) {
                    font-size: 20px;
                }
            }
    
            .list-icon {
                margin-right: 11px;
    
                img {
                    min-width: 18px;
                    max-width: 18px;
                    min-height: 18px;
                    max-height: 18px;
                }
            }
        }
    }
}


.widget-about {
    margin-bottom: 30px;

    @media (min-width:510px) {
        display: flex;
        justify-content: start;
        align-items: center;
        flex-wrap: wrap;
    }

    .widget-single {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 20px;
        max-width: 271px;

        @media (min-width:510px) {
            min-width: 48%;
            max-width: 48%;
        }

        p {
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            color: #555C71;
        }
    }

    .widget-icon {
        margin-right: 11px;

        img {
            min-width: 18px;
            max-width: 18px;
            min-height: 18px;
            max-height: 18px;
        }
    }

    .widget-content {
        padding-left: 20px;

    }
}