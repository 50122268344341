@use "../../abstract/" as *;

.comming-soon {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .comming-logo {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;

    }

    .section-title-block {
        text-align: center;
        margin: 30px 0;

        .comming-title {
            margin-bottom: 15px;
        }

        .btn {
            margin: 30px auto 0 auto;
        }
    }



    .form-single {
        max-width: 640px;
        margin: 0 auto;

        .form-control {
            padding-left: 20px;
            @media (max-width:479px) {
                margin-bottom: 15px;

            }

            @media (min-width:1200px) {
                max-width: 100%;

            }
        }

        .btn {
            min-height: 63px;
            max-height: 63px;
            border-radius: 5px;
            padding: 16px 23px;

            @media (max-width:479px) {
                width: 100%;

            }

            @media (min-width:480px) {
                border-radius: 0px 5px 5px 0px;
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        .cheackbox {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;

        }

        .cheackbox-label {
            font-weight: 400;
            font-size: 15px;
            color: #1A243F;
            line-height: 27px;
            position: relative;
            display: block;
            padding-left: 28px;
            margin-bottom: 12px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .cheackbox-label input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .checkmark {
            position: absolute;
            top: 6px;
            left: 0;
            height: 15px;
            width: 15px;
            border-radius: 2px;
            background: rgb(255, 255, 255, 0.3);
        }

        .cheackbox-label:hover input~.checkmark {
            background-color: #ccc;
        }

        .cheackbox-label input:checked~.checkmark {
            background-color: $primary;
        }

        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }

        .cheackbox-label input:checked~.checkmark:after {
            display: block;
        }

        .cheackbox-label .checkmark:after {
            left: 5px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}
.countdown {
    margin-bottom: 40px;

    @media (min-width:576px) {
        margin-bottom: 60px;

    }

    @media (min-width:992px) {
        margin-bottom: 80px;

    }

    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-left: -35px;
        margin-right: -35px;
    

        li {
            position: relative;
            display: flex;
            flex-direction: column;
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            text-align: center;
            padding: 20px 0;

            @media (max-width:479px) {
                width: 50%;
                &:nth-child(3){
                    span{
                        &:before{
                            visibility: hidden;
                        }
                        &:after{
                            visibility: hidden;
                        }
                    }
                }

            }
            @media (min-width:400px) {
                padding: 20px 0;

            }

            @media (min-width:768px) {
                padding: 20px 0;

            }

            span {
                margin-bottom: 0;
            }
            &:first-child{
                span{
                    &:before{
                        visibility: hidden;
                    }
                    &:after{
                        visibility: hidden;
                    }
                }
            }
            &>span{
                position: relative;
                padding: 0 10px;

                @media (min-width:400px) {
                    padding: 0 35px;

                }

                @media (min-width:768px) {
                    padding: 0 50px;

                }
                &::before,&:after {
                    width: 10px;
                    content: "";
                    position: absolute;
                    top: 30%;
                    left: -5px;
                    background: transparent;
                    height: 10px;
                    border-radius: 500px;
                    border: 2px solid $primary;
                }

                &:after {
                    top: 70%;
                }
                
            }

           
         
        }
    }
    &.dots-white{
        ul{
            li{
                &>span{
                    &:before, &:after{
                        border-color: #fff;
                    }
                }
            }
        }
    }

}

.comming-soon-1 {
    background-color: #1A243F;

    .error-image {
        margin-bottom: 30px;
    }

    .section-title-block {
        color: #fff;

        .comming-title {
            color: #fff;
        }
    }

    .countdown {

        ul {
            li {
                color: #FFFFFF;
                
                span {
                    color: #6460EF;
                }
            }
        }
    }

    .form-single {
        .form-control {

            @media (min-width:1200px) {
                max-width: 100%;

            }
        }

        .btn {
            color: #FFFFFF;
            background: #6460EF;

            &:hover {
                color: #FFFFFF;
                background-color: #4742ca;
                border-color: #4742ca;
            }
        }

    }

    .cheackbox {
        .cheackbox-label {
            color: rgb(255, 255, 255, 0.7);
        }
    }
}