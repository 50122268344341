
@use "../abstract" as *;
.widget-style-1{
    @media (min-width:428px) {
        display: flex;
    }
    .widget-icon{
        display: flex;
        justify-content: center;
        align-items: center;
        @include square(60px,500px);
        background: #FFFFFF;
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.09);
        @media (max-width:427px) {
            margin: 0 auto 15px auto;
        }
        span{
            font-size: 20px;
            color: $primary;
        }
    }
    .widget-text{
        
        @media (min-width:428px) {
            margin-left: 30px;
        }
        .widget-title{
            font-weight: 700;
            font-size: 22px;
            line-height: line-height(32,22);
            color: #171F36;
            margin-bottom: 15px;
            @media (max-width:427px) {
                text-align: center;
            }
        }
        p{
            font-weight: 400;
            font-size: 18px;
            line-height: line-height(30,18);
            color: #555C71;
            @media (max-width:427px) {
                text-align: center;
            }
        
        }
    }
}

.widget-post{
    display: flex;
    margin-bottom: 20px !important;
    .widget-image{
        margin-right: 20px;
        @media (max-width:380px) {
            max-width: 50px;
            margin-right: 18px;
            
        }
        img{
            width:100%;
        }
    }
    .widget-title{
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: #FFFFFF;
    }
    .widget-date{
        font-weight: 400;
        font-size: 15px;
        line-height: 27px;
        color: $text-light-opacity;
       
    }
    .widget-texts{
        margin-top: -7px;
        .title{
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            color: #FFFFFF;
        }
        .date{
            font-weight: 400;
            font-size: 15px;
            line-height: 27px;
            // color: $text-light-opacity;
        }
    }
}


.content-widget{
    margin-bottom:40px;
    @media(min-width:480px){
        display: flex;
    }
    &:last-child{
        margin-bottom:0;
    }
    .widget-icon{
        min-width: 60px;
        max-width: 60px;
        min-height: 60px;
        max-height:60px;
        background-color: #6460EF;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        border-radius: 500px;
        margin-bottom:20px;
        margin-left:auto;
        margin-right:auto;
        font-size: 20px;
        font-weight: 700;
        @media(min-width:480px){
            margin-right:20px;
        }
    }
    .widget-text{
        .widget-title{
            font-weight: 700;
            font-size: 22px;
            line-height: 32px;
            color: #171F36;
            margin-bottom:15px;
        }
        p{
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            color: #555C71;
            
        }
    }
}