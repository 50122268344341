@use "../../abstract" as *;
/*----------------------------
    testimonial Section Style 1 
------------------------------*/ 

.testimonial-section {
  background-color: #ffffff;
  position: relative;
  padding-top: 60px;
  padding-bottom: 30px;
  margin: 0;
  @media (min-width: 768px) {
    padding-top: 80px;
    padding-bottom: 50px;
  }
  @media (min-width: 992px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  @media (min-width: 1200px) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  
}
.testimonial-slider {
	position: static;
	width: 100%;
	margin: 0 auto;
	@media (min-width: 768px) {
		max-width: 70%;
	}
	.slick-arrow {
		@include square(50px, 500px);
		position: absolute;
		transition: 0.4s;
		top: 90%;
		z-index: 1;
		box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.15);
		@media (min-width: 992px) {
			top: 30%;
		}
		&:before {
			font-family: "icomoon";
			color: #000;
			font-size: 20px;
			line-height: 1;
			opacity: 0.75;
			-webkit-font-smoothing: antialiased;
		}
		&.slick-next {
			transition: 0.4s;
			right: 0%;
			@media (min-width: 992px) {
				right: 5%;
			}
			&::before {
				content: "\e917";
			}
		}
		&.slick-prev {
			transition: 0.4s;
			&::before {
				content: "\e92b";
			}
			left: 0%;
			@media (min-width: 992px) {
				left: 5%;
			}
		}
		&:hover {
			background: #6460ef;
			&:before {
				color: #ffffff;
			}
		}
	}

	.slick-dots {
		display: block;
		position: unset;
		padding-top: 20px;
		padding-left: 0;
		@media (min-width: 992px) {
			display: none !important;
		}

		li {
			position: relative;
			display: inline-block;
			width: 20px;
			height: 20px;
			margin: 0;
			padding: 0;
			cursor: pointer;
			button:before{
				font-size: 12px;
			}
		}
	}
}

.testimonial-ratings-block {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;

	.icon-single {
		margin: 5px;

		span {
			font-size: 20px;
			color: #ffca03;

			@media (min-width: 576px) {
				font-size: 25px;
			}
		}
	}
}

.testimonial-content {
	h4 {
		font-weight: 700;
		font-size: 22px;
		line-height: 28px;
		text-align: center;
		color: #171f36;
		margin-bottom: 40px;
		text-align: center;

		@media (min-width: 768px) {
			font-size: 24px;
			line-height: 34px;
		}

		@media (min-width: 992px) {
			font-size: 26px;
			line-height: 40px;
		}
	}
}
.user-block {
  text-align: center;

  .user-image {
    margin-bottom: 20px;

    img {
      margin: 0 auto;
	  border-radius: 500px;
    }
  }

  .user-details {
    .user-name {
      font-weight: 700;
      font-size: 18px;
      line-height: line-heigh(32, 22);
      text-align: center;
      color: #171f36;

      @media (min-width: 576px) {
        font-size: 22px;
      }
    }

    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      text-align: center;
      color: #1a243f;
      opacity: 0.51;

      @media (min-width: 576px) {
        font-size: 18px;
      }
    }
  }
}

