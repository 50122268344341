@use "../../abstract" as *;
/*----------------------------
    content Section Style 2 
------------------------------*/ 

.content-section-2{
    padding-top: 60px;
    padding-bottom: 60px;
    @media (min-width:768px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @media (min-width:992px) {
        padding-top: 50px;
        padding-bottom: 100px;
    }
    @media (min-width:1200px) {
        padding-top: 70px;
        padding-bottom: 120px;
    }
    .section-title-block{
        margin-bottom: 30px;
        text-align: center;
        @media(min-width:480px){
            text-align: start;
        }
        @media(min-width:992px){
            margin-bottom: 50px;
        }
        @media(min-width:1400px){
            padding-right: 60px;
           
        }
        .title{
            margin-bottom: 0;
            
        }
    }
  
    &__image-block{
        @media(max-width:1199px){
           margin-bottom: 50px;
        }
        .image{
            position: relative;
            img{
                width:100%;
                border-radius: 15px;
            }
            .image-2{
                position: absolute;
                right: -6%;
                bottom: -8%;
                max-width: 52%;
                @media(min-width:576px){
                    right: -17%;
                     bottom: -19%;
                }
                img{
                    border-radius: 15px;
                }
            }
        }
    }
    .content-widget-block{
        text-align: center;
        @media(min-width:480px){
            text-align: start;
        }
        @media(min-width:1400px){
            padding-right: 60px;
        }
    }
}



