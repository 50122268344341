@use "../../abstract" as *;
// use in home-1
.testimonial-card {
    background: #1a243f;
    border-radius: 5px;
    padding: 30px;
    position: relative;
    height: 100%;
    @media (min-width:380px) {
      padding: 38px 48px;
    }
    .card-texts {
      margin-bottom: 40px;
  
      p {
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        color: #ffffff;
      }
    }
  
    .user-details {
      @media (min-width:380px) {
        display: flex;
        align-items: center;
        
      }
  
      .user-image {
        min-width: 60px;
        max-width: 60px;
        min-height: 60px;
        max-height: 60px;
        margin-right: 20px;
  
        img {
          width: 100%;
        }
      }
  
      .user-texts {
        .user-name {
          color: #ffffff;
        }
  
        p {
          font-weight: 400;
          font-size: 18px;
          line-height: 30px;
          color: #ffffff;
          opacity: 0.51;
        }
      }
    }
  
    .card-icon {
      position: absolute;
      bottom: 38px;
      right: 68px;
      @include size(100px);
      img {
        width: 100%;
      }
    }
  }
  
  // use in home-2
  
  .testimonial-card-3 {
    background: #171f36;
    border-radius: 5px;
    padding: 25px 15px;
    position: relative;
    max-width: 500px;
    margin: 0 auto;
    height: 100%;
    border-radius: 5px;
    padding: 45px 43px 45px 43px;
  
    .user-details {
      margin-bottom: 20px;
  
      @media (min-width: 576px) {
        margin-bottom: 30px;
      }
  
      .user-image {
        min-width: 60px;
        max-width: 60px;
        min-height: 60px;
        max-height: 60px;
        margin-bottom: 10px;
  
        @media (min-width: 768px) {
          margin-bottom: 20px;
        }
  
        img {
          width: 100%;
        }
      }
  
      .user-texts {
        .user-name {
          color: #ffffff;
        }
  
        p {
          color: #ffffff;
          opacity: 0.51;
        }
      }
    }
  
    .card-texts {
      p {
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        color: #ffffff;
      }
    }
  
    .card-icon {
      position: absolute;
      top: 38px;
      right: 38px;
  
      span {
        font-size: 100px;
        color: rgb(255, 255, 255, 0.08);
      }
    }
}