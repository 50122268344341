@use "../../abstract" as *;
/*----------------------------
    About Section Style 2 
------------------------------*/ 
.about-section-2{
    background: rgb(85, 92, 113, 0.05);
    position: relative;
    padding-top: 60px;
    padding-bottom: 60px;
    @media (min-width:768px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @media (min-width:992px) {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @media (min-width:1200px) {
        padding-top: 120px;
        padding-bottom: 120px;
    }
    &__image{
        padding-right: 21%;
        position: relative;
        padding-bottom: 7%;
        margin-right: 2%;
        img{
            border-radius: 15px;
            width: 100%;
        }
        .video-icon{
            margin-bottom:29px;
        }
        .about-image-2{
            position: absolute;
            bottom: 0%;
            right: 0%;
            max-width: 52%;
            @media (min-width:1200px) {
                right: 0%;
            }
            img{
                border-radius: 10px;
            }
        }
    }
    &__content{
        .section-title-block{
            border-bottom: 1px solid rgb(26, 36, 63, 0.1);
            margin-bottom: 30px;
            padding-bottom: 12px;
            @media (min-width:575px) {
                margin-bottom: 40px;
                padding-bottom: 35px;
            }
            .title{
                text-align:start;
                @media (min-width:1400px) {
                    padding-right: 20px;
                }
            }
            p{
                @media (max-width:991px) {
                    margin-bottom: 10px;
                }
            }
        }
    }
}


.about-section-2__widget{
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    flex-direction: column;
    @media (min-width:480px) {
        align-items: center;
        flex-direction: row;
    }
    .widget-inner{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        margin-right:10px;
        @media (min-width:480px) {
            margin-bottom: 0;
        }
    }
    .widget-icon{
        img{
            @include square(50px,500px);
            @media (min-width:480px) {
                @include square(70px,500px);
            }
        }
    }
    .widget-content{
        padding-left: 20px;
        .title{
            font-weight: 700;
            font-size: 18px;
            line-height: 32px;
            color: #1A243F;
            margin-bottom: 0;
        }
        p{
            font-weight: 400;
            font-size: 16px;
            line-height: 27px;
            color: #555C71;
        }
    }
} 