@use "../../../abstract/" as *;

%blogBlockTitle{
  font-weight: 700;
  font-size: 30px;
  line-height: line-height(40,30);
  color: $heading-color;
}


.sidebar-padding{
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  @media (min-width:480px) {
    padding-top: 35px;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;
  }
}
.blog-post-details{
  padding-bottom: 50px;
  .post-image{
    margin-bottom: 30px;
    img{
      border-radius: 
      15px;
    }
  }
  .post-meta{
    display: flex;
    margin-bottom: 30px;
    .meta-single{
      display: flex;
      color: $heading-color;
      align-items: center;
      i{
        margin-right: 10px;
        color: $primary;
      }
      + .meta-single{
        &::before{
          content: "";
          display: block;
          @include square(5px,500px);
          background-color: rgba($heading-color,.2);
          @include mx(10px)
        }
      }
    }
  }
  .tag-wrapper{
    display: flex;
    margin: 0 -5px;
    .btn{
      color: #000;
      padding: 5.5px 20px;
      font-weight: 400;
      font-size: 15px;
      margin: 0 5px;
    }
  }
 .post-body{
  h2{
    @extend %blogBlockTitle;
    margin-top: 40px;
    margin-bottom: 15px;
  }
  p{
    font-weight: 400;
    font-size: 20px;
    line-height: line-height(30,20);
    color: #555C71;
    margin-bottom: 15px;
  }
  ul{
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    padding-top: 10px;
    li{
      font-weight: 400;
      font-size: 20px;
      line-height: line-height(30,20);
      color: #555C71;
      margin-bottom: 15px;
      transition: .4s;
      &:before{
        content: "\e914";
        font-family: "icomoon";
        display: inline-block;
        margin-right: 18px;
        transition: margin-right .4s;
        font-size: 15px;
      }
    }
  }
  blockquote{
    font-weight: 400;
    font-size: 30px;
    line-height: line-height(40,30);
    color: #171F36;
    max-width: 780px;
    padding-left: 23px;
    position: relative;
    margin-top: 20px;
    margin-bottom: 25px;
    &:before{
      content: "\e92c";
      font-family: "icomoon";
      position:absolute;
      top: 0;
      left: 0;
      color: rgba($primary,0.15);
      font-size: 45px;
    }
    div{
      text-align: right;
      margin-top: 20px;
      span{
        font-weight: 700;
        font-size: 18px;
        line-height: line-height(30,18);
        color: #171F36;
        position: relative;
        padding-top: 15px;
        &::before{
          content: "";
          position: absolute;
          width: 50px;
          height:1px;
          top: 0;
          left: 0;
          background-color: $heading-color;
        }
      }
    }
  }
 }
 .post-footer{
   display: flex;
   justify-content: space-between;
   margin: 0 -16px;
   flex-wrap: wrap;
   .social-list{
     list-style: none;
     margin-bottom: 0;
     padding-left: 0;
     display: flex;
     margin-top: 27px;
     margin-left: -10px;
     margin-right: -10px;
     a{
       padding: 0 10px;
       font-size: 15px;
       color: $heading-color;
       transition: .4s;
       &:hover{
         color: $primary;
       }
     }
   }
   &__single-block{
     padding: 10px 16px 0;
     order:2;
     @media (min-width:480px) {
      order:1;
    }
     &:last-child{
       order: 1;
       @media (min-width:480px) {
        order:2;
       }
     }
   }
   &-title{
    font-weight: 700;
    font-size: 22px;
    line-height: line-height(32,22);
    color: #171F36;
    margin-bottom: 20px;
   }
 }
}

