@use "../../abstract" as *;
/*----------------------------
    about Section Style 1 
------------------------------*/ 

.about-section{
    background: rgba(#555C71,0.05);
    padding-top: 60px;
    padding-bottom: 60px;
    @media (min-width:768px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @media (min-width:992px) {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @media (min-width:1200px) {
        padding-top: 120px;
        padding-bottom: 120px;
    }
    &__image-group{
        --bs-gutter-x:24px;
        --bs-gutter-y:24px;
        margin-bottom: 30px;
        @media (min-width:991px) {
            margin-bottom: 40px;
        }
        @media (min-width:1200px) {
            margin-bottom: 0px;
        }
       img{
        width: 100%;
        border-radius: 15px;
       }
    }
    &__content{
        @media (min-width:1200px) {
            padding-left: 63px;
        }
        .section-title-block{
            text-align: center;
            margin-bottom: 30px;
            @media (min-width:428px) {
                text-align: left;
            }
            @media (min-width:1200px) {
                margin-bottom: 50px;
            }
            .title{
                color: $big-stone;
            }
        }
        .about-widget-wrapper{
            >div+div{
                margin-top: 21px;
            }
        }
    }
}
