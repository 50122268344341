@use "../../../abstract/" as *;
.inner-post{
  .post-title{
    margin-bottom: 15px;
  }
  .post-sub-title{
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 700;
    line-height: line-height(30,20);
    color: #000;
  }
  p{
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    line-height: line-height(30,20);
    margin-bottom: 30px;
  }
  img{
    width: 100%;
    margin-top: 20px;
    border-radius: 15px;
  }
  ul{
    margin-bottom: 20px;
  }
  li{
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: $text-color;
    margin-bottom: 20px;
    &:last-child{
      margin-bottom: 0;
    }
    span{
      margin-right: 10px;
    }
  }
}

