@use "../../abstract" as *;
// use in home-1
.services-card {
  padding: 30px;
  border: 1px solid rgba(26, 36, 63, 0.1);
  border-radius: 5px;
  position: relative;
  transition: all 0.4s;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 1200px) {
    padding: 38px 35px;
  }

  .card-icon {
    @include size(60px);
    background: #6460ef;
    margin-bottom: 20px;
    transition: 0.4s;

    @media (min-width: 768px) {
      @include size(80px);
      margin-bottom: 29px;
    }

    i,
    span {
      font-size: 20px;
      color: #ffffff;

      @media (min-width: 768px) {
        font-size: 30px;
      }
    }
  }

  .card-title {
    color: #1a243f;
    margin-bottom: 13px;
    transition: all 0.4s;
  }

  .card-text {
    color: rgb(85, 92, 113);
    transition: all 0.4s;

    @media (max-width: 1399px) {
      padding-bottom: 50px;
    }
  }

  .btn {
    transition: all .4s;
    margin-right: -1px;
    margin-bottom: -1px;
    @include size(40px);
    @include flexAllCenter;
    background: $primary;
    border: 1px solid #fff;
    border-radius: 0;
    margin-top: 33px;
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 1;

    @media (min-width: 768px) {
      @include size(50px);
    }

    @media (min-width: 1400px) {
      opacity: 0;
      background: #ffffff;
    }

    span {
      color: #ffffff;
      font-weight: 500;
      font-size: 16px;
      transition: 0.4s;

      @media (min-width: 768px) {
        font-size: 20px;
      }
    }
  }

  @media (min-width: 1400px) {
    height: auto;

    margin-bottom: 33px;

    &:hover {
      background: #6460ef;
      border: 1px solid #fff;
      z-index: 10;
      margin-bottom: -33px;

      .card-text {
        margin-bottom: 33px;
      }

      .card-title,
      .card-text {
        color: #fff;
      }

      .card-icon {
        background-color: #fff;

        i,
        span {
          color: #6460ef;
        }
      }

      .btn {
        margin-top: 30px;
        opacity: 1;
        background-color: #fff;

        span {
          color: $primary;
        }
      }
    }
  }

  @media (min-width: 992px) {}
}

// use in home-2

.service-card-2 {
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.09);
  background: #ffffff;
  border-radius: 5px;
  transition: .4s;
  height: 100%;
  text-align: center;
  padding: 35px 30px;
  @media (min-width:768px) {
    text-align: left;
  }
  @media (min-width:1200px) {
    padding: 44px 45px;
  }
  .card-icon {
      @include square(60px,500px);
      @include flexAllCenter(inline-flex);
      background: rgb(100, 96, 239, 0.1);
      margin-bottom:20px;
      color: $primary;
      @media (min-width:768px) {
        @include square(80px,500px);
          margin-bottom:30px;
      }
      span {
        color: inherit;
          font-size: 20px;
          @media (min-width:768px) {
              font-size: 30px;
          }
      }
  }
  .title {
    color: #171F36;
    margin-bottom:13px;
    font-size: 20px;
    font-weight: 700;
    line-height: line-height(32,22);
    @media (min-width:768px) {
        font-size: 22px;
    }
  }
  p{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #555C71;
  }
  &:hover{
    background: $primary;
    .card-icon{
      background: rgb(255, 255, 255, 0.1);
    }
    .card-icon,.title{
      color:#ffffff;
    }
    p{
      color:rgb(255, 255, 255, 0.7);
    }
  }
}