@use "../../abstract" as *;
/*----------------------------
    hero Section Style 3
------------------------------*/ 

.hero-section-3 {
    position: relative;
    background: #1A243F;
    padding-top: 100px;
    padding-bottom: 60px;

    @media (min-width:576px) {
        padding-top: 120px;
        padding-bottom: 80px;
    }
    @media (min-width:768px) {
        padding-top: 150px;
        padding-bottom: 80px;
    }
    @media (min-width:992px) {
        padding-top: 200px;
        padding-bottom: 100px;
    }

    @media (min-width:1200px) {
        padding-top: 220px;
        padding-bottom: 145px;
    }

    &__content {
        text-align: center;
        @media (min-width:992px) {
            text-align: start;
            
        }
        @media (min-width:1400px) {
            padding-right: 30px;

        }

        .title {
            margin-bottom: 15px;
        }

        p {
            color: rgb(255, 255, 255);
            margin-bottom: 30px;

            @media (min-width:992px) {
                padding-right: 20px;
                margin-bottom: 50px;
            }
        }
    }

    &__button-group {
        @media (min-width:380px) {
            display: inline-flex;
            text-align: start;
            margin-bottom: 40px;
            margin-right:- 7.5px;
            margin-left:- 7.5px;
        }
        @media (min-width:992px) {
            margin-bottom: 70px;
        }
        .btn{
            @media (max-width:379px) {
                width: 100%;
                margin-bottom: 15px;
                
            }
            @media (min-width:380px) {
                margin-right: 7.5px;
                margin-left: 7.5px;
            }
        }
        


        .mr-15 {
            margin-right: 15px;
        }
        .btn-white-outline{
            &:hover{
                color: #000000;
            }
        }
    }

    &__widget-group {
        display: flex;
        justify-content: center;
        @media (min-width:992px) {
            justify-content: start;
        }
        .widget-single {
            max-width: 110px;
            margin-right: 50px;

            &:last-child {
                margin-right: 0;
            }

            .widget-title {
                font-weight: 700;
                font-size: 50px;
                line-height: 60px;
                color: #FFFFFF;
                margin-bottom: 4px;
            }

            p {
                padding: 0;
                margin-bottom:0;
                color: #FFFFFF;
            }
        }
    }

    &__image-block {
        margin-bottom: 50px;
        padding-left: 20%;
        @media (min-width:576px) {
            margin-bottom: 70px;
        }
        @media (min-width:768px) {
            margin-bottom: 80px;
        }
        @media (min-width:992px) {
            margin-bottom: 0;
        }

        .image-1 {
            position: relative;
            img {
                width: 100%;
                border-radius:15px;
            }

            .image-2 {
                position: absolute;
                bottom: -17%;
                left: -25%;
                max-width: 72%;
                
                img {
                    width: 100%;
                    border-radius:15px;
                }
            }
        }
    }
}