@use "../../abstract" as *;
/*----------------------------
    service Section Style 3
------------------------------*/ 

.service-section-3{
    padding-top: 50px;
    padding-bottom: 45px;
    @media (min-width:768px) {
        padding-top: 70px;
        padding-bottom: 62px;
    }
    @media (min-width:992px) {
        padding-top: 92px;
        padding-bottom: 82px;
    }
    @media (min-width:1200px) {
        padding-top: 108px;
        padding-bottom: 102px;
    }
    .section-title-block{
        
        .title{
            color: #171F36;
            text-align: center;
        }
    }
    .service-card{
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 11px;
        height: 100%;
        @media (min-width:768px) {
            padding: 25px;
        }
        .card-icon{
            @include square(60px,500px);
            @include flexAllCenter;
            background: rgb(100, 96, 239, 0.1);
            margin-bottom:20px;
            @media (min-width:576px) {
                @include square(80px,500px);
                margin-bottom:30px;
            }
            span{
                color: $primary;
                font-size: 20px;
                @media (min-width:576px) {
                    font-size: 30px;
                }
            }
        }
        .card-title{
            font-size: 18px;
            margin-bottom:10px;
            @media (min-width:576px) {
                margin-bottom:13px;
                font-size: 22px;
            }
        }
    }
}