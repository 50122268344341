@use "../abstract" as *;
%blogBlockTitle{
  font-weight: 700;
  font-size: 30px;
  line-height: line-height(40,30);
  color: $heading-color;
}

.sidebar-search{
  background: $secondary;
  border-radius: 5px;
  @include py(29px);
  @include px(26px);
  .form-item{
    margin-bottom: 20px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  .form-title{
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    color: #FFFFFF;
    margin-bottom: 30px;
    text-align: center;
  }
  .form-control{
    min-height: 55px;
    max-height: 55px;
    border-radius: 0;
    padding-left: 21px;
    padding-right: 45px;
  }
  i{
    position: absolute;
    top: 0;
    right: 17px;
    height: 100%;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.blog-sidebar{
  &__title{
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
  }
  &__list{
    margin-bottom: 0;
    li{
      font-weight: 400;
      font-size: 20px;
      line-height: line-height(30,20);
      color: $text-light-opacity;
      margin-bottom: 25px;
      transition: .4s;
      
      &:hover{
        color: $primary;
        a{
          &::before{
            margin-right: 14px;
          }
        }
      }
      &:last-child{
        margin-bottom: 0;
      }
      a{
       
        &:before{
          content: "\e914";
          font-family: "icomoon";
          display: inline-block;
          margin-right: 18px;
          transition: margin-right .4s;
          font-size: 15px;
        }
        color: inherit;
      }
    }
  }
}
.inner-sidebar{
  &__title{
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
  }
  &__list-normal{
    margin-bottom: 0;
    li{
      font-weight: 400;
      font-size: 20px;
      line-height: line-height(30,20);
      color: $text-light-opacity;
      margin-bottom: 20px;
      transition: .4s;
      
      // &:hover{
      //   color: $primary;
      //   a{
      //     &::before{
      //       margin-right: 14px;
      //     }
      //   }
      // }
      &:last-child{
        margin-bottom: 0;
      }
      a{
        color: inherit;
      }
    }
  }
}

.widget-post-wrapper{
  .widget-post{
    margin-bottom: 20px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}

.download-btn{
  background: #1A243F;
  .btn{
    span{
      font-size:13px;
      margin-right: 10px;
      svg{
        fill: #FFFFFF;
      }
    }
  }
  &:hover{
    background: $primary;
  }
}




.blog-details, .service-details{
  &__sidebar-single-right{
    margin-bottom: 50px;
    @media (min-width:1400px) {
      padding-left: 55px;
    }
    &:last-child{
      margin-bottom: 0;
    }
    &>div{
      border-radius: 5px
    };
  }
  &__sidebar-single{
    margin-bottom: 50px;
    @media (min-width:1400px) {
      padding-right: 55px;
    }
    &>div{
      border-radius: 5px
    };
  }
  &-body{
    padding-top: 60px;
    padding-bottom: 65px;
    @media (min-width:576px) {
      padding-top: 80px;
    }
    @media (min-width:768px) {
      padding-top: 100px;
    }
    @media (min-width:992px) {
      padding-top: 120px;
    }
    .comment-block{
      h2{
        @extend %blogBlockTitle;
        margin-bottom: 30px;
      }
    }
    .comment-form{
      padding-top: 50px;
      .form-title-block{
        .form-title{
          @extend %blogBlockTitle;
        }
      }
    }
  }
}