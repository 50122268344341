@use "../abstract" as *;

/*--------- form Gloabl Styles----- */
.form-title-block{
    margin-bottom: 40px;
     .form-title{
        font-weight: 700;
        font-size: 32px;
        line-height: line-height(50,40);
        color: #000000;
        margin-bottom: 15px;
        @media (min-width:768px) {
            font-size: 35px;
        }
        @media (min-width:992px) {
            font-size: 40px;
        }
    }
    p{
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #555C71;
    }
}
.form-control{
    min-height:60px;
    max-height:60px;
    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
}
.comment-form-box{
    padding-top: 100px;
    padding-bottom: 100px;
}
.contact-form-box{
    padding-top: 100px;
    padding-bottom: 100px;
}

/*--------- contact-form Styles----- */
.contact-form{
    width:100%;
    background: #FFFFFF;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
    padding: 20px 10px;
    @media (min-width:576px) {
        padding: 53px 50px;
    }
    .form-title-block{
        max-width: 555px;
    }
    form{
        .form-label{
            color:#000000;
            margin-bottom:10px;
        }
        .form-control{
            min-height:60px;
            max-height:60px;
            background: rgb(255, 255, 255, 0.1);
            
        }
        .form-wrapper{
            position: relative;
            span{
                position: absolute;
                color:rgb(26, 36, 63, 0.5);
                top: 21px;
                left: 20px;
            }
        }
        // .submit-btn{
        //     min-height: 60px;
        //     max-height: 60px;
        //     border-radius: 5px;
        //     background-color:$primary;
        //     border: 0;
        //     @media (max-width:768px) {
        //         min-width: 114px;
        //     }
        // }

    }
}

/*--------- contact-form Styles----- */
.comment-form{
    @media (max-width:991px) {
        margin-bottom: 40px;
    }
    form{
        .form-control{
            background: rgb(100, 96, 239, 0.05);
            border: 0;
        }
        textarea{
            min-height: 192px;
        }
        button{
            @media (max-width:767px) {
                width: 100%;
            }
        }
    }
}