@use "../../abstract" as *;

.focused-section{
    padding-bottom: 25px;
    @media (min-width:768px) {
        padding-bottom: 42px;
    }
    @media (min-width:992px) {
        padding-bottom: 82px;
    }
    @media (min-width:1200px) {
        padding-bottom: 102px;
    }
    .focused-widget-block{
        .widget-single{
            display: flex;
            padding-left: 15px;
            margin-bottom: 30px;
            @media (min-width:992px) {
                padding-right: 60px;
            }
            .widget-icon{
                margin-right: 20px;
                margin-top: 3px;
                span{
                    font-size: 25px;
                    color: $primary;
                }
            }
            .widget-text{
                .widget-title{
                    margin-bottom: 10px;
                }
                
            }
        }
    }
    .section-title-block{
        margin-bottom:30px;
        .title{
            text-align: start;
        }
        @media (min-width:1200px) {
            padding-left: 30px;
        }
        .title{
            @media (min-width:1200px) {
                padding-right: 30px;
            }
        }
    }
}