@use "../abstract" as * ;
.red-orange{
    color: rgba(250, 48, 48, 0.75);
}
.gradient-primary-secondary{
    color: linear-gradient(169deg, #5B6BE5 0%, #E45A91 46.88%, #F7A355);
}
.text-footer{
    color: $text-color;
}
.header-bg{
    background: #1A243F;
}


