@use "../../abstract" as *;
/*----------------------------
    hero Section Style 2 
------------------------------*/ 

.hero-area-2 {
    position: relative;
    z-index: 1;
    &__button-group {
        @media (min-width:360px) {
            display: flex;
            align-items: center;
        }
        .btn {
            margin-bottom: 20px;
            @media (min-width:360px) {
                margin-right: 10px;
                margin-bottom: 0;
            }
            &:last-child {
                margin-right: 0;
                
            }
        }
        
    }
    &__content {
        position: relative;
        .title {
            color: #FFFFFF;
            margin-bottom: 15px;
    
        }
    
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: #FFFFFF;
            margin-bottom: 50px;
    
            @media (min-width:768px) {
                font-size: 20px;
                line-height: 30px;
            }
        }
    }
    &__widget-box {
        background: linear-gradient(15.16deg, #1A243F 2.91%, rgba(25, 25, 17, 0) 120.84%);
        border-radius: 5px 0px 0px 5px;
        backdrop-filter: blur(50px);
        max-width: 804px;
        margin-right: auto;
        position: absolute;
        bottom: 0;
        right: 0;
        display: none;
        @media (min-width:480px) {
            display: block;
            display: flex;
            flex-wrap: wrap;
            z-index: 2;
        }
        .single-feature {
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            @media (min-width:480px) {
                padding: 20px;
            }
            @media (min-width:768px) {
                padding: 30px;
            }
            @media (min-width:992px) {
                padding: 40px;
            }
            &:last-child {
                background: #171F36;
                border-radius: 0px 0px 5px 0px;
                &:hover{
                    background-color: $primary;
                    transition: .4s;
                }
                .single-feature__image {
                    margin-bottom: 10px;
                    max-width: 40px;
                    margin: 0 auto;
                    @media (min-width:992px) {
                        max-width: 50px;
                    }
                    img{
                        width: 100%;
                    }
                }
            }
            &__image {
                margin-bottom: 10px;
                span {
                    font-size: 25px;
                    color: #ffffff;
                    @media (min-width:768px) {
                        font-size: 36px;
                    }
                    @media (min-width:992px) {
                        font-size: 50px;
                    }
                }
            }

            h3 {
                font-weight: 400;
                font-size: 11px;
                line-height: line-height(27, 16);
                color: #FFFFFF;
                @media (min-width:992px) {
                    font-size: 16px;
                }
            }
        }
    }
    .hero-slider-dots{
        
        width: 100%;
        height: 60px;
        position: absolute;
        bottom: 0;
        left: 0;
        @media (min-width:480px) {
            height: 160px;
        }
        @media (min-width:768px) {
            display: flex;
            align-items: center;
            height: 170px;
        }
        .slick-dots{
            display: flex;
        }
        .slick-dots li {
            width: 30px !important;
            height: auto;
            margin: 3px;
            padding: 5px;
        }
    }
    .slick-dots li button:before {
        font-family: 'slick';
        font-size: 40px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 30px;
        height: 2px;
        background: rgb(255, 255, 255, 0.5);
        text-align: center;
        // opacity: .25;
        color: black;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .slick-dots{
        position: relative;
        padding-left: 20px;
        &:before{
            content: "01";
            top: -9px;
            left: 0;
            position: absolute;
            color:rgb(255, 255, 255) ;
        }
        &:after{
            content: "03";
            top: -9px;
            left: 135px;
            position: absolute;
            color:rgb(255, 255, 255) ;
        }
        .slick-dots li {
            width: auto;
            height: auto;
            padding: 5px;
        }
        .slick-dots li button{
            width: auto;
            height: auto;
        }
        
    }

}
.hero-slider {
    margin-bottom: 0 !important;
    .slick-track{
        display:flex;
        
    }
    .single-slide {
        height: auto;
        z-index: 1;
        padding-top: 120px;
        padding-bottom: 70px;

        @media (min-width:480px) {
            padding-top: 160px;
            padding-bottom: 200px;
        }
        @media (min-width:576px) {
            padding-top: 160px;
            padding-bottom: 200px;
        }

        @media (min-width:768px) {
            padding-top: 180px;
            padding-bottom: 220px;
        }
        @media (min-width:992px) {
            padding-top: 180px;
            padding-bottom: 250px;
        }

        @media (min-width:1200px) {
            padding-top: 290px;
            padding-bottom: 290px;
        }
        &:before{
            content:"";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(269.59deg, #1A243F 34.74%, rgba(26, 36, 63, 0.82) 72.97%, rgba(26, 36, 63, 0) 100.92%);
            transform: matrix(-1, 0, 0, 1, 0, 0);
            z-index: -1;
            @media (min-width:576px) {
                background: linear-gradient(269.59deg, #1A243F 34.74%, rgba(26, 36, 63, 0.82) 46.97%, rgba(26, 36, 63, 0) 85.92%);

            }

        }

    }
   
}


