@use "../abstract" as *;

.breadcrumb{
    background-image: url(../image/png/breadcrumb-bg-image.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding-top: 44px;
    padding-bottom: 36px;
    margin-bottom: 0;
    @media (min-width:768px) {
        padding-top: 64px;
        padding-bottom:56px;
    }
    @media (min-width:992px) {
        padding-top: 84px;
        padding-bottom:76px;
    }
    @media (min-width:1200px) {
        padding-top: 104px;
        padding-bottom:96px;
    }
    &-title{
        font-weight: 700;
        font-size: 34px;
        line-height: line-height(80, 70);
        margin-bottom: 15px;
        color: #FFFFFF;
        @media (min-width:768px) {
            font-size: 48px;
        }
        @media (min-width:992px) {
            font-size: 54px;
        }
        @media (min-width:1200px) {
            font-size: 64px;
        }
        @media (min-width:1400px) {
            font-size: 70px;
        }
    }
    ul{
        padding-left: 0;
        display: flex;
        flex-wrap: wrap;
        li{
            font-weight: 400;
            font-size: 18px;
            line-height:line-height(30, 20);
            color: #FFFFFF;
            list-style: none;
            @media (min-width:768px) {
                font-size: 20px;
            }
            &:not(&:first-child){
                &::before{
                    content: "\e914";
                    font-family: "icomoon";
                    display: inline-flex;
                    margin: 0 5px;
                    font-size: 13px;
                    @media (min-width:768px) {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}


.breadcrumb-large{
    position: relative;
    background-image: url(../image/png/breadcrumb-bg-image.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    z-index: 1;
    padding-top: 50px;
    @media (min-width:768px) {
        padding-top: 70px;
    }
    @media (min-width:992px) {
        padding-top: 90px;
    }
    @media (min-width:1200px) {
        padding-top: 110px;
    }
    &:before{
        content:"";
        position:absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 10%;
        background-color: #fff;
        z-index: -11;
    }
    .breadcrumb-content{
        @media (max-width:575px) {
            text-align: center;
        }
    }
    &__title{
        font-weight: 700;
        font-size: 34px;
        line-height: line-height(80, 70);
        margin-bottom: 15px;
        color: #FFFFFF;
        text-align: center;
        @media (min-width:768px) {
            text-align: start;
            font-size: 48px;
        }
        @media (min-width:992px) {
            font-size: 54px;
        }
        @media (min-width:1200px) {
            font-size: 64px;
        }
        @media (min-width:1400px) {
            font-size: 70px;
        }
    }
    p{
        text-align: center;
        @media (min-width:768px) {
            text-align: start;
        }
    }
    .btn{
        @media (max-width:767px) {
            margin: 0 auto;
        }
    }
    .breadcrumb-list{
        padding-left: 0;
        display: flex;
        @media (max-width:767px) {
            justify-content: center;
            }
        li{
            font-weight: 400;
            font-size: 18px;
            line-height:line-height(30, 20);
            color: #FFFFFF;
            list-style: none;
            @media (min-width:768px) {
                font-size: 20px;
            }
            &:not(&:first-child){
                &::before{
                    content: "\e914";
                    font-family: "icomoon";
                    display: inline-flex;
                    margin: 0 5px;
                    font-size: 13px;
                    @media (min-width:768px) {
                        font-size: 15px;
                    }
                }
            }
        }
    }
    .breadcrumb-image{
        margin-top: 40px;
        @media (min-width:768px) {
            margin-top: 50px;
        }
        @media (min-width:992px) {
            margin-top: 60px;
        }
        @media (min-width:1200px) {
            margin-top: 84px;
        }
        img{
            // border-bottom-right-radius: 170px;
            width: 100%;
        }
    }
}