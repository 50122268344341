@use "../../abstract" as *;

.option-section{
    padding-top: 50px;
    @media (min-width:768px) {
        padding-top: 70px;
    }
    @media (min-width:992px) {
        padding-top: 92px;
    }
    @media (min-width:1200px) {
        padding-top: 108px;
    }
}