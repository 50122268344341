@use "../../abstract" as *;
/*----------------------------
    blog Section Style 1 
------------------------------*/ 

.blog-section{
    padding-top: 60px;
    padding-bottom: 60px;
    @media (min-width:768px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @media (min-width:992px) {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @media (min-width:1200px) {
        padding-top: 120px;
        padding-bottom: 120px;
    }
    .section-title-block{
        text-align: center;
        margin-bottom:30px;
        @media (min-width:768px) {
            margin-bottom:50px;
        }
        @media (min-width:992px) {
            margin-bottom:60px;
        }
        @media (min-width:1200px) {
            margin-bottom:80px;
        }
        .title{
            text-align: center;
        }
    }
    .row{
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 1.5rem;
    }
    // .blog-card{
    //     .card-image{
    //         position: relative;
    //         overflow: hidden;
    //         border-radius: 15px;
    //         img{
    //             width: 100%;
    //             border-radius: 15px;
    //         }
    //     }
    //     .card-image:hover .blog-image-hover{
    //         transform: translateY(0%);
    //     }
    //     .blog-image-hover{
    //         display: flex;
    //         transition: 0.7s;
    //         align-items: flex-end;
    //         justify-content: center;
    //         position: absolute;
    //         bottom: 0;
    //         left: 0;
    //         right: 0;
    //         width: 100%;
    //         height: 100%;
    //         padding: 20px 20px;
    //         background: linear-gradient(184.1deg, rgba(26, 36, 63, 0) -0.16%, rgba(26, 36, 63, 0.83) 66.64%, #1A243F 93.16%);
    //         @media (min-width:992px) {
    //             padding: 30px 40px;
    //             transform: translateY(100%);
    //             margin: 0;
    //         }
    //         .hover-text{
    //             .hover-title{
    //                 font-weight: 700;
    //                 font-size: 22px;
    //                 line-height: 32px;
    //                 color: #FFFFFF;
    //                 margin-bottom: 13px;
    //             }
    //             .subtitle{
    //                 font-family: 'Mulish';
    //                 font-style: normal;
    //                 font-weight: 400;
    //                 font-size: 18px;
    //                 line-height: 30px;
    //                 color: #FFFFFF;
    //                 margin-bottom: 18px;
    //             }
    //             .hover-btn{
    //                 .btn{
    //                     font-weight: 700;
    //                     font-size: 18px;
    //                     line-height: 27px;
    //                     color: #FFFFFF;
    //                     text-decoration: underline;
    //                     padding: 0;
    //                 }
    //             }
    //         }
    //     }
    // }
}



