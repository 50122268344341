@use "../../abstract" as *;

.contact-fact-section{
    padding-top: 30px;
    padding-bottom: 55px;
    @media (min-width:768px) {
        padding-top: 50px;
        padding-bottom: 80px;
    }
    @media (min-width:992px) {
        padding-top: 70px;
        padding-bottom: 100px;
    }
    @media (min-width:1200px) {
        padding-top: 90px;
        padding-bottom: 120px;
    }

    .section-title-block{
        text-align: center;
        margin-bottom: 40px;
        @media (min-width:768px) {
            margin-bottom: 60px;
        }
        @media (min-width:992px) {
            margin-bottom: 80px;
        }
        .title{
            text-align: center;
            @media (min-width:992px) {
                padding:0 90px;
            }
            @media (min-width:1400px) {
                padding:0 190px;
            }
        }
        p{
            @media (min-width:1400px) {
                padding: 0 40px;
            }
            
        }
    }
   
}