@use "../../abstract" as *;
/* -------
fact Cards 
--------------------*/

.fact-card {
    text-align: center;
    background: rgb(84, 91, 112, 0.05);
    border: 1px solid rgba(255, 250, 250, 0.05);
    border-radius: 15px;
    padding: 53px;
    height: 100%;
  
    .card-icon {
      margin-bottom: 20px;
  
      span {
        font-size: 70px;
        color: #6460ef;
      }
    }
  
    .card-body {
      padding: 0 15px 0 0;
  
      .title {
        color: #171f36;
        margin-bottom: 3px;
      }
  
      .text {
        color: #555c71;
      }
    }
  }
  
  .justify-center {
    justify-content: center;
  
    @media (min-width: 768px) {
      justify-content: start;
    }
  }