@use "../../abstract" as *;
/* -------
portfolio Cards 
--------------------*/
.portfolio-card {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
  
    .card-image {
      border-radius: 13px;
  
      @media (max-width: 767px) {
        max-height: 300px;
      }
  
      img {
        width: 100%;
      }
    }
  
    .card-body {
      display: flex;
      flex-direction: column;
      transition: 0.4s;
      justify-content: end;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      padding: 20px 20px;
      background: linear-gradient(176.3deg,
          rgba(255, 255, 255, 0) -26.9%,
          rgba(0, 0, 0, 0.66) 92.34%);
  
      .card-title {
        color: #ffffff;
      }
  
      p {
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 1px;
        color: #ffffff;
        margin-bottom: 5px;
      }
    }
  
    .card-link {
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      color: #ffffff;
      text-decoration: underline;
      padding: 0;
    }
  
    &:not(.hover-visible) {
      .card-body {
        @media (min-width: 992px) {
          transform: translateY(150%);
          transition: 0.4s;
          padding: 30px;
        }
      }
  
      &:hover {
        .card-body {
          @media (min-width: 992px) {
            transform: translateY(0%);
          }
        }
      }
    }
  }