@use "../../abstract" as *;
/*----------------------------
    fact Section Style 1 
------------------------------*/ 

.fact-section{
    position: relative;
    z-index: 1;
    background: #FFFFFF;
    padding-top: 60px;
    padding-bottom: 60px;
    @media (min-width:768px) {
        padding-top: 80px;
        padding-bottom: 0;
    }
    @media (min-width:992px) {
        padding-top: 100px;
    }
    @media (min-width:1200px) {
        padding-top: 108px;
    }
    &::before{
        content:"";
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgb(26, 36, 63);
        width: 100%;
        height: 48%;
        z-index: -1;
        @media (max-width:768px) {
            height: 100%;
        }
    }
    .section-title-block{
        margin-bottom: 30px;
        @media (min-width:768px) {
            margin-bottom: 50px;
        }
        @media (min-width:992px) {
            margin-bottom: 60px;
        }
        @media (min-width:1200px) {
            margin-bottom: 80px;
        }
        .title{
            color: #FFFFFF;
            text-align: center;
           
        }
    }
    .video-block{
        position: relative;
        img{
            width: 100%;
            border-radius: 5px;
            @media (max-width:767px) {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
    
    .fact-group{
        position: relative;
        z-index: 2;
        @media (max-width:767px) {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
        @media (min-width:768px) {
            margin-left: 6%;
            margin-right: 6%;
            margin-top: -6%;
        }
        @media (min-width:992px) {
            padding: 40px 20px;
        }
        @media (min-width:1200px) {
            margin-left: 7%;
            margin-right: 7%;
            padding: 76px 73px;
        }
    }
}



