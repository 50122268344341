@use "../../abstract" as *;

.service-section{
    padding-top: 60px;
    @media (min-width:768px) {
        padding-top: 80px;
    }
    @media (min-width:992px) {
        padding-top: 100px;
    }
    @media (min-width:1200px) {
        padding-top: 120px;
    }
}