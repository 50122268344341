@use "../../abstract" as *; 

.team-section{
    padding-top: 50px;
    padding-bottom: 50px;
    @media (min-width:768px) {
        padding-top: 66px;
        padding-bottom: 70px;
    }
    @media (min-width:992px) {
        padding-top: 86px;
        padding-bottom: 90px;
    }
    @media (min-width:1200px) {
        padding-top: 106px;
        padding-bottom: 110px;
    }
    .section-title-block{
        .title{
            color: $big-stone;
        }
    }
}