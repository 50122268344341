@use "../abstract/" as *;

.video-icon {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include square(50px,500px);
  background: $primary;
  @include flexAllCenter;
  @media (min-width:768px) {
    top: 40%;
    left: 50%;
  }
  @media (min-width:992px) {
    // @include square(80,500);
    @include size(80px)
  }

  span {
    font-size: 15px;
    border-radius: 0;
    color: #fff;
  }
}

.video-icon--sm {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include square(50px,500px);
  background: $primary;
  @include flexAllCenter;
  @media (min-width:992px) {
    @include size(70px);
  }
  span {
    font-size: 15px;
    border-radius: 0;
    color: #fff;
  }
}

.btn {
  font-weight: 700;
  line-height: line-height(27,18);
  color: #FFFFFF;
  border-radius: 5px;
  @include flexAllCenter;
  width: fit-content;
  font-size: 16px;
  padding: 12px 20px;
  @media (min-width:576px) {
    font-size: 18px;
    padding: 16px 23px;
    
  }
}

.btn-black {
  background: rgb(41, 48, 56);
  color: #fff;
}

.btn-primary,
.btn-secondary {
  position: relative;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    top: -50%;
    right: -100%;
    bottom: -50%;
    left: -100%;
    background: linear-gradient(to bottom, rgba(229, 172, 142, 0), rgba(255, 255, 255, 0.5) 50%, rgba(229, 172, 142, 0));
    transform: rotateZ(60deg) translate(-3em, 15em);
  }

  &:hover::after,
  &:focus::after {
    animation: sheen 1s forwards;
  }
}

@keyframes sheen {
  100% {
    transform: rotateZ(60deg) translate(1em, -15em);
  }
}

.btn-white-outline {
  transition: .4s;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  &:hover{
    background: #fff;
    color: #171F36;
  }
}
.hover-primary{
  &:hover{
    background: $primary;
    color: #FFFFFF;
  }
}
.btn-primary-opacity{
  background: rgb(100, 96, 239, 0.1);
}