@use "../../abstract/" as *;

.comming-soon-3{
    background-image: url(../image/png/comming-bg-1.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    position:relative;
    &:before{
        content:'';
        background: rgb(26, 36, 63, 0.8);
        width: 100%;
        height:100%;
        position:absolute;
        top:0;
        left:0;
        z-index: -1;
    }
    .section-title-block{
        color: #fff;
        .comming-title{
            color: #fff;
        }
    }
    .countdown{
        li{
            color: #FFFFFF;
            span{
                color: #FFFFFF;
                margin-bottom:0;
            }
        }
    }

    .form-single{
        
        .btn{
            background: transparent;
            background-color: #4742ca;
            border: 0;
            padding-left: 23px;
            padding-right: 23px;
            @media (max-width:479px) {
                width:100%; 
                color: #FFFFFF;
            }
            @media (min-width:480px) {
                border-radius: 0px 5px 5px 0px;
                position: absolute;
                top: 0;
                right: 0;  
            }
            span{
                padding-left: 10px;
            }
            &:hover{
                color: #FFFFFF;
                background-color: #4742ca;
                border-color: #4742ca;
            }
        }
    }
}