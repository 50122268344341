@use "../abstract" as *;
/* -------
cta global style
--------------------*/


.section-padding {
    padding-top: 50px;
    padding-bottom: 50px;

    @media (min-width:768px) {
        padding-top: 66px;
        padding-bottom: 70px;
    }

    @media (min-width:992px) {
        padding-top: 86px;
        padding-bottom: 90px;
    }

    @media (min-width:1200px) {
        padding-top: 106px;
        padding-bottom: 110px;
    }
}

.form-single {
    position: relative;
    @media (min-width:1200px) {
        max-width: 471px;

    }

    @media (max-width:1199px) {
        max-width: 700px;
        margin: 0 auto;

    }

    .form-control {
        position: relative;
        font-size: 15px;
        min-height: 63px;
        max-height: 63px;
        margin-bottom: 30px;

        @media (min-width:480px) {
            margin-bottom: 0;

        }
    }
}


/* -------
cta-section style......use in home-1
--------------------*/
.cta-section {
    background: #1A243F;
    .cta-widget {
        display: flex;
        align-items: center;

        p {
            color: #FFFFFF;
        }
    }
    
    .section-title-block {
        margin: 0 !important;

        .title {
            color: #FFFFFF;
            margin: 0 !important;
            text-align: center;
            @media (min-width:1200px) {
                text-align: start;
    
            }
        }
    }
    .form-single {
        position: relative;
        .form-control{
            color: #000;
            padding: 20px 145px 20px 20px;
            @media (max-width:379px) {
                margin-bottom: 15px;
    
            }
        }
        .btn {
            padding: 17px 23px !important;
            font-size: 18px !important;
            @media (max-width:379px) {
                width:100%;
    
            }
            @media (min-width:380px) {
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 0 5px 5px 0;
    
            }
        }
    }
}

/* -------
cta-section style......use in home-2
--------------------*/
.cta-section-2 {
    z-index: 1;
    padding-top: 60px;
    padding-bottom: 60px;
    position: relative;
    z-index: 1;
    @media (min-width:768px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @media (min-width:992px) {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    @media (min-width:1200px) {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    &:before {
        content: "";
        background: rgb(26, 36, 63, 0.8);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
    }

    .section-title-block {
        margin: 0 !important;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            color: #fff;
            margin-bottom: 30px;
            text-align: center;
            @media (min-width:768px) {
                margin-bottom: 50px;
            }
        }
    }
}
/* -------
cta-section style......use in home-3
--------------------*/

.cta-section-3 {
    background-image: url(../image/png/cta-bg-img.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
    padding-top: 60px;
    padding-bottom: 60px;

    @media (min-width:576px) {
        padding-top: 120px;
        padding-bottom: 80px;
    }
    @media (min-width:768px) {
        padding-top: 120px;
        padding-bottom: 80px;
    }

    @media (min-width:992px) {
        padding-top: 220px;
        padding-bottom: 100px;
    }

    @media (min-width:1200px) {
        padding-top: 260px;
        padding-bottom: 100px;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(358.62deg, #1A243F 1.71%, rgba(26, 36, 63, 0.04) 97.02%, rgba(26, 36, 63, 0) 169.55%);
        z-index: -1;
        width: 100%;
        height: 100%;
    }

    .section-title-block {
        color: #FFFFFF;
        margin-bottom: 30px !important;

        @media (min-width:992px) {
            max-width: 745px;
        }

        .title {
            color: #FFFFFF;
            padding-right: 20px;
            @media (min-width:992px) {
                padding-right: 0;
            }
        }

    }
    .cta-contact-widget {
        margin-bottom:20px;
        @media (min-width:480px) {
            display: flex;
            align-items: center;
        }
        @media (min-width:992px) {
            margin-bottom:0;
        }


        .widget-item {
            display: flex;
            align-items: center;

            @media (min-width:480px) {
                margin-right: 20px;
                &:last-child{
                    margin-right: 0;
                }
            }
            @media (min-width:576px) {
                margin-right: 25px;
                &:last-child{
                    margin-right: 0;
                }
            }
            @media (min-width:768px) {
                margin-right: 50px;
                &:last-child{
                    margin-right: 0;
                }
            }
            @media (max-width:992px) {
                margin-bottom: 20px;
            }

            span {
                font-size: 25px;
                color: #FFFFFF;
                margin-right: 20px;

                @media (min-width:480px) {
                    margin-right: 10px;
                }
                @media (min-width:576px) {
                    margin-right: 15px;
                }
                @media (min-width:768px) {
                    margin-right: 28px;
                }
                @media (min-width:992px) {
                    font-size: 35px;
                    margin-right: 28px;
                }
            }

            .widget-title {
                font-weight: 700;
                font-size: 18px;
                line-height: line-height(32,22);
                color: #FFFFFF;
                margin: 0;
                @media (min-width:576px) {
                    font-size: 20px;
                }
                @media (min-width:992px) {
                    font-size: 22px;
                }
            }
        }
    }
    .widget-block {
        @media (min-width:992px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

/* -------
cta-section style......use in all inner page
--------------------*/
.cta-section-4{
    background: $primary;
    padding-top: 50px;
    padding-bottom: 60px;

    @media (min-width:768px) {
        padding-top: 70px;
        padding-bottom: 80px;
    }

    @media (min-width:992px) {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .section-title-block {
        color: #FFFFFF;
        margin: 0 !important;

        @media (max-width:1199px) {
            text-align: center;
        }

        @media (min-width:1400px) {
            max-width: 590px;
        }

        .title {
            color: #FFFFFF;
        }

        p {
            color: #ffffff;

        }
    }
    .form-control{
        margin-bottom: 15px;
        height: 100%;
        max-height: 50px !important;
        @media (min-width:401px) {
            padding-right: 170px;
            max-height: 60px;
        }
        &::placeholder{
            color: $big-stone;
        }

    }

    .cta-form-subtext {
        display: flex;
        align-items: center;

        @media (max-width:1199px) {
            justify-content: center;

        }

        margin-top: 15px;

        span {
            margin-right: 10px;
        }

        p,span {
            font-weight: 400;
            font-size: 14px;
            line-height: 25px;
            color: #FFFFFF;
        }
    }

    .btn{
        color:#FFFFFF;
        background: #1A243F;
        border-radius: 0px 5px 5px 0px;
        padding: 18px 23px;
        @media (max-width:400px) {
            width: 100%;
            border-radius: 5px;
        }
        @media (min-width:401px) {
            
            position: absolute;
            top: 0;
            right: 0;
        }
         
    }
}