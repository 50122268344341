$white              :#fff;
$black              :#000;
$cornflower-blue : #6460EF;
$comet : #555C71;
$big-stone : #171F36;
$heading-color: $big-stone;
$text-color:$comet;
$text-light:$white;
$text-light-opacity:rgba($white,.7);
$header-link-color: $cornflower-blue;
$btn-link-color: $cornflower-blue;

$primary: $cornflower-blue;
$secondary: $big-stone;
$text: $comet;


