@use "../../abstract" as *;
/*----------------------------
    testimonial Section Style 3
------------------------------*/ 

.testimonial-section-3 {
    background-color: rgb(26, 36, 63);
    .section-title-block {
        text-align: center;

        .title {
            text-align: center;
            color: #ffffff;
        }
    }

    .testimonial-slider {
        position: relative;
        z-index: 1;
    }
    .testimonial-slider-3 {
        margin-right: -12px;
        margin-left: -12px;
        .slick-track{
            display: flex;
            .slick-slide{
                height: auto;
            }
        }
        .single-slider {
            padding-right: 12px;
            padding-left: 12px;
        }
    }
    .slider-pagination {
        @media (max-width:767px) {
            display: flex;
            margin: 0 auto;
            justify-content: center;
            width: 100%;
            margin-top: 30px;
            
        }
        .prev {
            @media (max-width:767px) {
               
                margin-right: 20px;
                
            }
            @media (min-width:768px) {
                position: absolute;
                top: 50%;
                left: -25px;
                transform: translateY(-50%);
                z-index: 1;
            }
        }

        .next {
            @media (min-width:768px) {
                position: absolute;
                top: 50%;
                right: -25px;
                transform: translateY(-50%);
                z-index: 1;
            }
        }

    }
}