@use "../abstract" as *;
$sticky-header-bg: $secondary;

.site-header {

	&.site-header--transparent,
	&#sticky-header {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 99;
	}

	&.site-header--bg-gradient {
		background: linear-gradient(180deg, rgba(26, 36, 63, 0.75) 0%, rgba(26, 36, 63, 0) 100%);
	}

	&>.container {
		position: relative;
	}

	&#sticky-header {
		transition: transform 0.4s, background 0.4s;

		@media (min-width: 320px) {
			position: fixed;

			&.scrolling {
				transform: translateY(-100%);

				// .navbar-brand {
				// 	max-width: 110px;
				// 	@media (min-width: 768px) {
				// 		max-width: 130px;
				// 	}
				// }
				&.reveal-header {
					background-color: $sticky-header-bg;
					transform: translateY(0%);
				}
			}
		}
	}

	.navbar {
		padding-top: 20px;
		padding-bottom: 20px;

		.navbar-nav {
			display: flex;
			justify-content: flex-end;

			@media (min-width:1200px) {
				margin-left: 23px !important;
				margin-right: 76px;
			}

			@media (min-width:1400px) {
				margin-left: 110px;
				margin-right: 76px;
			}

			.nav-item {
				font-weight: 400;
				font-size: 16px;
				line-height: pxToEm(22, 18);
				padding-left: 12px;
				padding-right: 12px;
				position: relative;
				transition: 0.5s;

				a {
					display: block;
				}

				@media (min-width:1200px) {
					font-size: 18px;
					padding-left: 16px;
					padding-right: 16px;
				}

				@media (min-width:1400px) {
					padding-left: 24px;
					padding-right: 24px;
				}

				.nav-link {
					padding: 10px;

					@media (min-width:992px) {
						padding: 21px 10px;
					}

				}

				&.dropdown {
					@media (min-width:992px) {
						&>.dropdown-menu {
							min-width: 15rem;
							top: 120%;
							transition: top 0.4s;

							&>.dropdown {
								&>.dropdown-menu {
									top: 0%;
									right: -110%;
									transition: right 0.4s;
								}

								&:hover {
									&>.dropdown-menu {
										right: -100%;
									}
								}
							}
						}

						&:hover {
							&>.dropdown-menu {
								top: 100%;
							}
						}
					}
				}
			}

			.dropdown {
				&>.nav-link {
					&:after {
						content: "\e923";
						font-family: "icomoon";
						font-size: 10px;
						position: absolute;
						top: 17px;
						right: 30px;
						transform: translateY(-50%);
						padding-top: 4px;
						font-weight: 600;
						pointer-events: none;

						@media (min-width:992px) {
							top: 50%;
							right: 6px;
						}

						@media (min-width:1200px) {
							top: 50%;
							right: 10px;
						}

						@media (min-width:1400px) {
							right: 15px;
						}
					}
				}

				.dropdown-menu {
					background-color: #fff;
					border-bottom: 5px solid $primary;
					width: 100%;
					padding: 15px 0px;

					@media (min-width:992px) {
						max-width: 238px;
						margin-top: 0;
						display: block !important;
						visibility: hidden;
						pointer-events: none;
						opacity: 0;
					}

					@media (max-width:991px) {
						background-color: transparent;
						border-radius: 0;
						box-sizing: content-box;
						border-right: 0;
						border-top: 0;
						border-bottom: 0;
						padding-top: 0;
						padding-bottom: 0;
						margin-bottom: 15px;
						position: relative !important;
						border-left: 0;

						&:before {
							content: "";
							border-left: 3px solid $primary;
							height: 100%;
							position: absolute;
							top: 0;
							left: 13px;

						}

					}

					.dropdown-item {
						color: #000;
						font-weight: 600;
						padding: 0;
						transition: .3s;

						&:hover {
							background-color: #fff;
							color: $primary;
						}

						a {
							padding: 9px 22px 9px 30px;

							@media (min-width:992px) {
								padding: 9px 22px 9px 30px;
							}
						}
					}

					.dropdown {
						&>.submenu-link {
							&:after {
								content: "\e923";
								font-family: "icomoon";
								color: #000;
								font-size: 10px;
								position: absolute;
								top: 17px;
								right: 20px;
								transform: translateY(-50%);
								padding-top: 0;
								font-weight: 700;
								pointer-events: none;

								@media (min-width:992px) {
									content: "\e914";
								}

								@media (min-width:1400px) {
									right: 20px;
								}

							}
						}

					}

					.dropdown-menu {
						&:before {
							content: "";
							left: 35px;
						}

						.dropdown-item {
							a {
								padding-left: 50px;
							}
						}
					}

					a {
						color: inherit;
					}

					&.show {
						@media (min-width:992px) {
							margin-top: 0;
							display: none !important;
						}
					}
				}

				&:hover {
					@media (min-width:992px) {
						&>.dropdown-menu {
							visibility: visible;
							pointer-events: visible;
							opacity: 1;
						}
					}
				}
			}
		}

		.navbar-collapse {
			@media (min-width:992px) {
				margin-left: auto;
			}

			@media (min-width:1400px) {
				padding-right: 50px;
			}
		}
	}

	.btn {
		span {
			font-size: 15px;
			margin-right: 10px;
		}
	}

	&__button {
		.btn {
			margin-left: 25px;

			@media (max-width:991px) {
				padding: 10px;
			}

		}
	}
}

.navbar-toggler {
	margin-left: auto;
	border-color: rgba(#fff, .7) !important;

	&:focus {
		box-shadow: none;
		outline: none;
	}
}

.navbar-brand {
	max-width: 190px;

	@media (min-width:1200px) {
		max-width: 234px;
	}
}

@media (max-width:991px) {
	// .container {
	// 	position: relative;
	// }

	.navbar-collapse {
		background: #fff;
		position: absolute;
		width: 100%;
		left: 0;
		top: 100%;
		z-index: 999;
		box-shadow: rgb(0 0 0 / 30%) -2px 2px 70px -25px;

		.collapse-inner {
			padding-bottom: 30px;
			padding-top: 10px;
			overflow-y: scroll;
			max-height: 400px;

			&::-webkit-scrollbar {
				width: 5px;
			}

			&::-webkit-scrollbar-track {
				background: none;
				border-left: 1px solid rgba($primary, 0.1);
			}

			&::-webkit-scrollbar-thumb {
				background: $primary;
				border-radius: 5px;
			}

			@media (min-width:576px) {
				padding-bottom: 0;
			}
		}

		.navbar-nav {
			.nav-item:not(:last-child) {
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			}
		}

	}

	.navbar {
		position: static;
	}
}

.widget-schedule {
	display: flex;
	align-items: center;
	color: #FFFFFF;
	font-weight: 500;
	font-size: 18px;
	line-height: pxToEm(22, 18);
	padding-top: 4px;
	font-size: 16px;
	padding-right: 10px;
	padding-left: 10px;

	@media(min-width:1200px) {
		padding-right: 23.5px;
		padding-left: 23.5px;
	}

	.widget-icon {
		padding-right: 12px;
	}

	span {
		font-weight: 500;
		line-height: pxToEm(22, 18);
		font-size: 14px;
		color: #fff;

		@media (min-width:768px) {
			font-size: 18px;
		}
	}
}