@use "../../abstract"as *;
/*----------------------------
    testimonial Section Style 2 
------------------------------*/ 

.testimonial-section-2{
    padding-top: 60px;
    padding-bottom: 60px;
    position: relative;
    @media (min-width:768px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @media (min-width:992px) {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @media (min-width:1200px) {
        padding-top: 120px;
    padding-bottom: 120px;
    }
    .section-title-block{
        margin-bottom: 40px;
        @media (min-width:992px) {
            padding-right: 10px;
            margin-bottom: 50px;

        }
        @media (min-width:1200px) {
            margin-bottom: 0;

        }
        @media (max-width:1200px) {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .testimonial-btn{
            margin-top:30px;
           
        }
    }
    .slick-track{
        display: flex;
        .slick-slide{
            height: auto;
        }
    }
    .slider-area{
        position: relative;
        z-index: 1;
        .slider-testimonial-2{
            margin-right: -5px;
            margin-left: -5px;
        }
        .single-slider{
            padding-right: 5px;
            padding-left: 5px;
        }
    }
    .slider-position {
        @media (max-width:575px) {
            display: flex;
            margin: 0 auto;
            justify-content: center;
            // width: 100%;
            margin-top: 30px;
        }
        @media (min-width:576px) {
            position: absolute;
            display: block;
            margin-bottom: 30px;
            top:50% ;
            right: -25px;
            transform: translateY(-50%);
        }
        .slider-btn {
            cursor: pointer;
            min-width: 50px;
            max-width: 50px;
            min-height: 50px;
            max-height: 50px;
            color: #000000;
            background-color: #FFFFFF;
            border-radius: 500px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 5px;
            margin-bottom: 5px;
            box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.25);
            transition: .3s;
            @media (max-width:575px) {
                margin-left: 10px;
                margin-right: 10px;
            }
            span{
              color: #000000;
            }
            &:hover{
                background-color: #6460EF;
                box-shadow: none;
            }
            &:hover span{
              color: #ffffff;
            }
            
        }
    }
}