@use "../../abstract" as *;
/*----------------------------
    portfolio Section Style 3
------------------------------*/ 

.portfolio-section {
    position:relative;
    &::before{
        content:"";
        position: absolute;
        bottom: -1px;
        left: 0;
        background-color: rgb(26, 36, 63);
        width: 100%;
        height: 11%;
        z-index: -1;
        @media (min-width:992px) {
            height: 32%;
        }
    }
    .section-top{
        margin-bottom:60px ;
        @media (max-width:767px) {
            display: flex;
            align-items: center;
            flex-direction: column;
        }
        @media (max-width:1399px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    .section-title-block {
        text-align: center;
        margin-bottom:0 !important;
        @media (min-width:768px) {
            text-align: start;
        }
        @media (min-width:1400px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .title {
            @media (min-width:768px) {
                text-align: start;
            }
           
            color: #000000;
            @media (max-width:1399px) {
                margin-bottom:15px;
            }
        }

        .text {
            @media (max-width:767px) {
                margin-bottom:20px;
            }
            @media (min-width:576px) {
                max-width: 444px;
            }
        }

       
    }
}