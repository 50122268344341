@use "../abstract" as * ;

.title{
  margin-bottom: 15px;
  color: $big-stone;
}
span,a{
  display: inline-block;
}
.form-control{
  &::placeholder{
    color: $big-stone;
  }
}

.bg-commet-opacity{
  background-color: rgba($comet,0.05);
}
.gy24{
  --bs-gutter-y: 1.5rem;
}
.box-shadow{
  box-shadow: -4px 8px 12px rgb(0 0 0 / 10%);
}
.slider-btn{
  cursor:pointer;
}

.bg-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
}

.slider-pagination {
  display: flex;
  align-items: center;
  .slider-btn {
      min-width: 50px;
      max-width: 50px;
      min-height: 50px;
      max-height: 50px;
      color: #000000;
      background-color: #FFFFFF;
      border-radius: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 5px;
      margin-bottom: 5px;
      box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.25);
      transition: .3s;
      @media (max-width:575px) {
          margin-left: 10px;
          margin-right: 10px;
      }
      span{
        color: #000000;
      }
      &:hover{
          background-color: #6460EF;
      }
      &:hover span{
        color: #ffffff;
      }
      
  }
}
.pagination{
  margin-top: 50px;
  margin-left: -10px;
  margin-right: -10px;
  justify-content: center;
  .page-item{
    .page-link{
      @include square(50px, 500px);
      @include flexAllCenter;
      box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.09);
      border: 0;
      transition: .3s;
      color: #000000;
      margin-left: 10px;
      margin-right: 10px;
      span{
        color: #000000;
      }
      &:hover{
        background-color: #6460EF;
        color: #fff;
        span{
          color: #ffffff;
        }
      }
    }
  }
}
.error-title, .count-list, .comming-title{
  text-align: center;
  font-weight: 700;
  font-size: 35px;
  line-height: line-height(80,70);
  color: $big-stone;
  margin-bottom: 15px;
  @media (min-width:480px){
      font-size: 46px;
  }
  @media (min-width:576px){
      font-size: 54px;
  }
  @media (min-width:768px){
      font-size: 62px;
  }
  @media (min-width:992px){
      font-size: 70px;
  }
}

.site-header--fill-dark{
	background-color: #1A243F;
}
.site-header--fill-light{
	background-color: #fff;
}

.site-header--dark{
  .navbar{
    .navbar-nav{
      .nav-item {
        .nav-link {
          color: #000;
          @media (min-width:992px) {
            color: #fff;
          }
          
        }
      }
    }
    .dropdown{
      &>.nav-link{
        &:after{
          color:#000;
          @media (min-width:992px) {
            color:#fff;
          }
        }
      }
    }
  }
  .btn{
    color: rgb(255, 255, 255, 0.9);
    background: $primary;
    @media (min-width:576px) {
      background-color: transparent;
    }
    border-color: #fff;
    @media (max-width:575px) {
      &:hover{
        background: rgb(33, 33, 180);
        color: #fff;
      }
    }
  }
}
.site-header--light{
  .navbar{
    .navbar-nav{
      .nav-item {
        .nav-link {
          color: #000;
          
        }
      }
    }
    .dropdown{
      &>.nav-link{
        &:after{
          color:#000;
        }
      }
    }
  }
  .btn{
    color: rgb(255, 255, 255, 0.9);
			background: $primary;
      &:hover{
        background: rgb(33, 33, 180);
        color: #fff;
      }
  }
}