@use "../../../abstract" as *;

.blog-2-colum{
    padding-top: 50px;
    padding-bottom: 60px;
    @media (min-width:768px) {
        padding-top: 75px;
        padding-bottom:80px;
    }
    @media (min-width:992px) {
        padding-top: 90px;
        padding-bottom:100px;
    }
    @media (min-width:1200px) {
        padding-top: 105px;
        padding-bottom:120px;
    }
}