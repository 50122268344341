/*----------------------------
    brand Section Style 1 
------------------------------*/ 

.brand-section-padding{
    padding-top: 430px ;
    @media (min-width:480px) {
        padding-top: 330px;
    }
    @media (min-width:768px) {
        padding-top: 90px;
        padding-bottom: 55px;
    }
    @media (min-width:992px) {
        padding-top: 95px;
        padding-bottom: 75px;
    }
    @media (min-width:1200px) {
        padding-top: 95px;
        padding-bottom: 88px;
    }
}