@use "../../abstract" as *;

/* Card Gloabl Styles */
.card-title {
  &:not(.card-title--lg):not(.card-title--sm):not(.card-title--xs) {
    font-weight: 700;
    font-size: 22px;
    line-height: line-height(32, 22);
  }

  &--lg {
    font-weight: 700;
    font-size: 54px;
    line-height: line-height(65, 54);
  }

  &--sm {
    font-weight: 700;
    font-size: 18px;
    line-height: line-height(27, 18);
  }

  &--xs {
    font-weight: 700;
    font-size: 12px;
    line-height: line-height(20, 12);
  }
}

.card-text {
  &:not(.card-text--lg):not(.card-text--sm) {
    font-weight: 400;
    font-size: 18px;
    line-height: line-height(30, 18);
  }

  &--lg {
    font-weight: 400;
    font-size: 16px;
    line-height: line-height(27, 18);
  }

  &--md {
    font-weight: 500;
    font-size: 18px;
    line-height: line-height(32, 22);

    @media (min-width: 480px) {
      font-size: 20px;
    }

    @media (min-width: 576px) {
      font-size: 22px;
    }
  }

  &--sm {
    font-weight: 700;
    font-size: 12px;
    line-height: line-height(20, 12);
  }
}

.card-icon-round {
  @include flexAllCenter;
  border-radius: 500px;
}
