@use "../../abstract/" as *;

// use in home-1, case study, casestudy 2 column
.case-study-card {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  @media (max-width:1199px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .card-image {
    img {
      width: 100%;
      @media (max-width:991px) {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        
      }
      @media (min-width:992px) {
        border-radius: 15px;
      }
    }
  }
  .card-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(255, 255, 255);
    padding: 30px;
    width: 100%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    @media(min-width:992px){
      border-radius: 5px;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 35px 30px;
      bottom: 30px;
      left: 30px;
      width: calc(100% - 60px);
    }

    @media (min-width: 1200px) {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 35px 30px;
      bottom: 30px;
      left: 30px;
      width: calc(100% - 60px);
    }

    &__texts {
      .subtitle {
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 1px;
        color: #6460ef;
        margin-bottom: 5px;
      }

      .card-title {
        font-weight: 700;
        font-size: 22px;
        line-height: 32px;
        color: #171f36;
        margin-bottom: 0;
      }
    }

    &__button {
      min-width: 36px;
      max-width: 36px;
      min-height: 36px;
      max-height: 36px;
      background: rgb(100, 96, 239);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 500px;
      transition: 0.4s;
      color: #fff;
      margin-bottom: 3px;

      @media (min-width: 992px) {
        min-width: 50px;
        max-width: 50px;
        min-height: 50px;
        max-height: 50px;
      }

      i {
        font-size: 15px;

        @media (min-width: 992px) {
          font-size: 18px;
        }
      }

      a {
        span {
          font-size: 15px;
          margin-bottom: 3px;
          color: #fff;

          @media (min-width: 992px) {
            font-size: 18px;
          }
        }
      }

      &:hover {
        .card-body {
          &__button {
            background: rgb(28, 20, 245);
          }
        }
      }
    }
  }
  &:not(.hover-visible) {
    .card-body {
      @media (min-width: 992px) {
        transform: translateY(150%);
        transition: 0.4s;
        padding: 35px 30px;
      }
    }

    &:hover {
      .card-body {
        @media (min-width: 992px) {
          transform: translateY(0%);
        }
      }
    }
  }
  .hover-visible {
    @media (min-width: 992px) {
      transform: translateY(0%);
      transition: 0.4s;
      padding: 35px 30px;
    }
  }
}

// use in home-2
.case-card-2 {
  border-radius: 5px;
  overflow: hidden;
  height: 100%;

  @media (min-width: 1200px) {
    height: 100%;
    display: flex;
  }

  .card-image {
    width: 100%;
    position: relative;

    @media (min-width: 1200px) {
      max-width: 50%;
    }

    img {
      width: 100%;
      height: 250px;
      object-fit: cover;
      object-position: top;

      @media (min-width: 1200px) {
        border-radius: 5px 0 0 5px;
        height: 100%;
      }
    }
  }

  .card-body {
    width: 100%;
    transition: 0.4s;
    background: #1a243f;
    padding: 30px 30px;

    @media (min-width: 1200px) {
      border-radius: 0px 5px 5px 0px;
      max-width: 50%;

      &:hover {
        background: #6460ef;
      }
    }
    @media (min-width: 1400px) {
      padding: 68px 40px;
    }

    .card-content {
      margin-bottom: 30px;

      .subtitle {
        color: #ffffff;
        margin-bottom: 5px;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
      }

      .title {
        font-size: 22px;
        line-height: line-height(32, 22);
        color: #ffffff;
        margin-bottom: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        max-height: 120px;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        @media (min-width: 1200px) {
          font-size: 22px;
        }
      }

      p {
        font-size: 18px;
        line-height: line-height(30, 18);
        color: #ffffff;
        opacity: 0.7;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        max-height: 120px;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;

        @media (min-width: 1200px) {
          font-size: 18px;
        }
      }
    }

    .case-card-btn {
      font-family: $font-mulish;
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      color: #ffffff;
      text-decoration: underline;
      padding: 0;
      transition: 0.4s;

      @media (min-width: 1200px) {
        font-size: 18px;
      }

      &:hover {
        text-decoration: none;
      }
    }

    @media (min-width: 992px) {
      display: flex;
      flex-direction: column;

      .card-btn {
        margin-top: auto;
      }
    }
  }
}