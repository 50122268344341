@use "../../abstract" as *; 

.project-section{
    position: relative;
    background: $big-stone;   
    padding-top: 60px;
    padding-bottom: 40px;
    @media (min-width:768px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @media (min-width:992px) {
        padding-top: 10%;
        padding-bottom: 10%;
    }
    @media (min-width:1200px) {
        padding-top: 15%;
        padding-bottom: 15%;
    }
    @media (min-width:1400px) {
        padding-top: 200px;
        padding-bottom: 250px;
    }
    .section-title-block{
        @media (max-width:767px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
        .title{
            color: #fff;
            text-align: center;
            @media (min-width:768px) {
                text-align: start;
            }
        }
        @media (min-width:1200px) {
            padding-right: 85px;
        }
        p{
            color: rgb(255, 255, 255, 0.7);
            margin-bottom: 20px;
            @media (min-width:992px) {
                margin-bottom: 35px;
            }
        }
        .btn{
            background: transparent;
            padding-left: 65px;
            transition: .4s;
            &:before{
                content: "";
                width: 40px;
                height: 1px;
                background: #fff;
                position: absolute;
                top: 50%;
                left: 0;
                transition: .4s;
            }
            &:hover{
                color: rgb(255, 255, 255);
                margin-left: 40px;
                &:before{
                    content: "";
                    width: 80px;
                    
                }
            }
           
        }
        .btn-block{
            position: relative;
           
            
           
        }
    }
    .project-image{
        margin-bottom: 30px;
        @media (min-width:768px) {
            margin-bottom: 0;
        }
        @media (min-width:992px) {
            position: absolute;
            right: 0;
            top: -5%;
            max-width: 52%;
        }
        @media (min-width:1200px) {
            max-width: 60%;
        }
        img{
            width: 100%;
        }
    }
}