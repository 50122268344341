@use "../../../abstract" as *;

/* Card Gloabl Styles */
.fact-area {
    background-color: #fff;
    padding-top: 60px;
    padding-bottom: 60px;

    @media (min-width:768px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @media (min-width:992px) {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    @media (min-width:1200px) {
        padding-top: 108px;
        padding-bottom: 100px;
    }

    // .fact-single {
    //     margin-bottom: 15px;

    //     @media (min-width:576px) {
    //         margin-bottom: 0;
    //     }
    // }
}

.fact-title {
    font-weight: 700;
    font-size: 32px;
    line-height: line-height(65, 54);

    @media (min-width:992px) {
        font-size: 40px;
    }

    @media (min-width:1200px) {
        font-size: 54px;
    }
}


.fact-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;

    @media (min-width:992px) {
        font-size: 18px;
    }
}

.single-icon {
    margin: 0 auto;
    margin-bottom: 20px;

    @media (min-width:768px) {
        margin-bottom: 30px;
    }

    span {
        font-size: 45px;
        color: #6460EF;
        display: flex;
        justify-content: center;

        @media (min-width:768px) {
            font-size: 70px;
        }

        @media (min-width:1200px) {
            font-size: 80px;
        }
    }
}

/* -------------------------Card component Styles------------------ */

.fact-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
    background: #FFFFFF;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
    z-index: 2;

    @media (min-width:480px) {
        padding: 30px 30px;
    }

    @media (min-width:992px) {
        padding: 40px 20px;
    }

    @media (min-width:1200px) {
        padding: 90px 73px;
    }

    >div {
        padding: 0 15px;
    }
}

.fact-custom-item {
    width: 100%;

    @media (min-width:380px) {
        min-width: 50%;
        max-width: 50%;
    }

    @media (min-width:480px) {
        min-width: 50%;
        max-width: 50%;
    }

    @media (min-width:992px) {
        margin-bottom: 0;
        min-width: 25%;
        max-width: 25%;
    }

    .fact-title {
        text-align: center;
        color: #171F36;
        margin-bottom: 4px;
    }

    p {
        text-align: center;
        color: #555C71;
        font-weight: 400;
        font-size: 18px;
        line-height: line-height(30, 18);
    }
}

/* -------------------------Card component Styles------------------ */

/*home-2 fact section Styles */
.fact-section-2 {
    background: #1A243F;
    padding-top: 60px;
    padding-bottom: 60px;

    @media (min-width:992px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @media (min-width:1200px) {
        padding-top: 100px;
        padding-bottom: 100px;
    }
   

    .fact-box {
        .row {
            --bs-gutter-x: 1.5rem;
            --bs-gutter-y: 2rem;
        }

        .fact-single {
            margin-bottom: 15px;
            @media (min-width:576px) {
                margin-bottom: 0;
            }

            .fact-content {
                text-align: center;

                .fact-title {
                    color: #ffffff;
                    margin-bottom: 3px;
                }

                p {
                    text-align: center;
                    color: #ffffff;
                }
            }
        }
    }
}


/*home-3 fact section Styles */
.fact-section-3 {

    .fact-box {
        padding-top: 60px;
        padding-bottom: 60px;

        @media (min-width:768px) {
            padding-top: 100px;
            padding-bottom: 100px;
        }

        @media (min-width:992px) {
            padding-top: 80px;
            padding-bottom: 80px;
        }

        @media (min-width:1200px) {
            padding-top: 100px;
            padding-bottom: 100px;
        }

        .row {
            --bs-gutter-x: 1.5rem;
            --bs-gutter-y: 2rem;
        }

        .fact-single {
            background: rgb(85, 92, 113, 0.05);
            border: 1px solid rgba(255, 250, 250, 0.14);
            border-radius: 5px;
            width: 100%;
            padding: 50px 25px;

            @media (min-width:480px) {
                padding: 53px 25px;
            }
            @media (min-width:768px) {
                padding: 53px 25px;
            }

            @media (min-width:992px) {
                padding: 30px 25px;
            }

            @media (min-width:1200px) {
                padding: 53px 25px;
            }

            .fact-content {
                text-align: center;

                .fact-title {
                    color: #171F36;
                    margin-bottom: 3px;
                }

                p {
                    text-align: center;
                    color: #171F36;
                }
            }
        }
    }
}

/*Service fact section Styles */
.fact-section-1 {
    padding-top: 60px;
    padding-bottom: 30px;

    @media (min-width:768px) {
        padding-top: 70px;
        padding-bottom: 50px;
    }

    @media (min-width:992px) {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    @media (min-width:1200px) {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .section-title-block {
        margin-bottom: 30px;
        @media (min-width:768px) {
            margin-bottom: 50px;
        }

        @media (min-width:1200px) {
            text-align: start;
            margin: 0 !important;
        }

    }

    .fact-group {
        box-shadow: none;
        padding: 0;

        .fact-custom-item {
            min-width: 100%;
            text-align: center;

            @media (min-width:576px) {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            @media (min-width:1200px) {
                margin-bottom: 25px;
                min-width: 100%;
                max-width: 100%;
            }

            .item-single {
                display: flex;
                align-items: center;
                margin-bottom: 30px;

            }

            .single-icon {
                margin: 0;
                margin-bottom: 0;

                span {
                    margin-right: 30px;
                }
            }

            .fact-title {
                text-align: start;
                color: #171F36;
            }

            p {
                text-align: start;
                color: #555C71;
            }
        }
    }
}

