@use "../../abstract/" as *;

.case-details-body {
  padding-top: 60px;
  padding-bottom: 45px;

  @media (min-width:576px) {
    padding-top: 80px;
    padding-bottom: 65px;
  }
  @media (min-width:992px) {
    padding-top: 120px;
    padding-bottom: 65px;
  }

  .inner-post{
    img{
      margin-top: 0;
    }
  }
}

.case-study-details {
  img {
    margin-bottom: 20px;

    @media (min-width:992px) {
      margin-bottom: 25px;
    }
  }
}

.right-list-block {
  @media (min-width:1400px) {
    padding-left: 30px;
  }

  p {
    margin-bottom: 20px;
  }

  ul {
    margin-bottom: 30px;

    @media (min-width:992px) {
      margin-bottom: 0;
    }

    li {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        color: $primary;
      }
    }
  }
}

.contact-widget {
  padding-top: 30px;
  display: flex;
  align-items: center;
  @media (min-width:576px) {
    padding-top: 50px;
  }
  span {
    margin-right: 22px;
    font-size: 40px;
    color: $primary;
  }

  .widget-text {
    .sub-title {
      margin-bottom: 5px;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      color: #555C71;
    }
    .number{
      margin-bottom: 5px;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      color: #000;
      &:hover{
        color: $primary;
      }
    }
  
  }
}

.bottom-border {
  border-bottom: 1px solid rgb(0, 0, 0, 0.1);
}